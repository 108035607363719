import React, {useEffect, useState, useRef, useCallback} from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import img1 from "../assets/about/1.png";
import { Imprints } from "../components/Imprints";
import img2 from "../assets/about/2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleCheck,
    faUserCheck,
    faCalendar,
    faStar,
    faDesktop,
    faTableCellsLarge,
    faList,
    faClock,
    faDollar,
    faXmark,
    faFilter
  } from "@fortawesome/free-solid-svg-icons";
import { CourseCard } from "../components/CourseCard";
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiUrl } from '../config/Config';
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { Drawer, ButtonToolbar, Button, Placeholder } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import Spinner from "react-activity/dist/Spinner";

const AllCourses = () => {
    const [categorydata, setcategorydata] = useState([]);
    const [selectedindex, setselectedindex] = useState(null);
    const [resultsdata, setresultsdata] = useState([]);
    const [showloading, setshowloding] = useState(false);
    const [viewas, setviewas] = useState(0);

    const [modalIsOpen, setmodalIsOpen] = useState(false);
    const [selectedcoursename, setselectedcoursename] = useState(null);
    const [selectedcountry, setselectedcountry] = useState(null);
    const [countriesdropdown, setcountriesdropdown] = useState([]);
    const [selectedcity, setselectedcity] = useState(null);
    const [citiesdropdown, setcitiesdropdown] = useState([]);
    const [selectedmonth, setselectedmonth] = useState(null);
    const [monthsdropdown, setmonthsdropdown] = useState([]);
    const [inpersonbatchesdata, setinpersonbatchesdata] = useState([]);
    const [shownobatchesavailable, setshownobatchesavailable] = useState(false);
  
    const [liveonlinebatchesdata, setliveonlinebatchesdata] = useState([]);
  
    const [schedulecheck, setschedulecheck] = useState(0);

    const [consultmodalIsOpen, setConsultmodalIsOpen] = useState(false);

    const [Filteropen, setFilterOpen] = useState(false);
  
  
  
    const selectedcoursenameRef = useRef(null);
    selectedcoursenameRef.current = selectedcoursename;
  
    const navigate = useNavigate();
  
    const InpersonSearchbtn = () => {
      var diablestatus = true;
      if (selectedcountry && selectedmonth) {
        if(schedulecheck == 0) {
          if(selectedcity) {
            diablestatus = false;
          } else {
            diablestatus = true;
          }
        } else {
          diablestatus = false;
        }
        
      } else {
        diablestatus = true;
      }
      return (
        <button
          type="button"
          className="btn btn-primary modalsearchbtn schedulecheckmodalsearchbtn"
          onClick={() => {
            if(schedulecheck == 0) {
              filterinpersonbatches();
            } else {
              filterliveOnlinebatches();
            }
            }}
          disabled={diablestatus}
        >
          Search
        </button>
      );
    };
  
    const filterinpersonbatches = async () => {
      setshownobatchesavailable(false);
      var splitdate = selectedmonth?.split("--");
      // console.log('splitdate >>', splitdate);
      var data = JSON.stringify({
        country: selectedcountry,
        city: selectedcity,
        fromdate: splitdate[0],
        enddate: splitdate[1],
        // course: courseslug,
        course: selectedcoursenameRef?.current,
      });
      const headers = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let resp = await axios
        .post(apiUrl + "getinpersonbatches", data, headers)
        .then((res) => {
          console.log("res >>>", res.data.data);
          setinpersonbatchesdata(res.data.data);
          if (res.data.data?.length == 0) {
            setshownobatchesavailable(true);
          } else {
            setshownobatchesavailable(false);
          }
          // setcitiesdropdown(res.data.data);
          // setshowloding(false);
        })
        .catch((err) => {
          alert(err.message);
        });
    };
  
    const filterliveOnlinebatches = async () => {
      setshownobatchesavailable(false);
      var splitdate = selectedmonth?.split("--");
      var data = JSON.stringify({
        country: selectedcountry,
        fromdate: splitdate[0],
        enddate: splitdate[1],
        // course: courseslug,
        course: selectedcoursenameRef?.current,
      });
      const headers = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let resp = await axios
        .post(apiUrl + "getliveonlinebatches", data, headers)
        .then((res) => {
          console.log("res >>>", res.data.data);
          setliveonlinebatchesdata(res.data.data);
          if (res.data.data?.length == 0) {
            setshownobatchesavailable(true);
          } else {
            setshownobatchesavailable(false);
          }
          // setshowloding(false);
        })
        .catch((err) => {
          alert(err.message);
        });
    };
  
    const handleinpersonclassroomclick = (data) => {
      console.log("data >>", data);
      closeModal1();
      navigate("/cart", {
        state: {
          categoryslug: data?.category,
          courseslug: selectedcoursenameRef?.current,
          coursename: selectedcoursenameRef?.current,
          price: data?.offerprice,
          type: "In Person Classroom",
          country: data?.country,
          city: data?.city,
          startdate: data?.startdate,
          enddate: data?.enddate,
          starttime: data?.starttime,
          endtime: data?.endtime,
          weektype: data?.weektype,
        },
      });
    };
  
    const handleliveonlineclassroom = (data) => {
      console.log("handleliveonlineclassroom data >>", data);
      closeModal1();
      navigate("/cart", {
        state: {
          categoryslug: data?.category,
          courseslug: selectedcoursenameRef?.current,
          coursename: selectedcoursenameRef?.current,
          price: data?.offerprice,
          type: "Live Online Classroom",
          country: data?.country,
          city: data?.city,
          startdate: data?.startdate,
          enddate: data?.enddate,
          starttime: data?.starttime,
          endtime: data?.endtime,
          weektype: data?.weektype,
        },
      });
    };
  
    const selectedcoursefunc = (t) => {
      console.log('selectedcoursefunc t >>', t)
      setselectedcoursename(t);
      setmodalIsOpen(true);
      setselectedcountry(null);
      setselectedcity(null);
      setselectedmonth(null);
      setinpersonbatchesdata([]);
      setliveonlinebatchesdata([]);
      setshownobatchesavailable(false);
    }
  
    const handlechangecheckboxCourse = (status, num) => {
      if(status) {
        setschedulecheck(num);
      }
    }
  
    const fetchcountriesdropdown = async() => {
      // setshowloding(true);
      const headers = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let resp = await axios
        .get(apiUrl + "getschedulecountries", headers)
        .then((res) => {
          setcountriesdropdown(res.data.countries);
          // setshowloding(false);
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  
    const fetchinpersoncities = async (country) => {
      var data = JSON.stringify({
        country: country,
      });
      const headers = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let resp = await axios
        .post(apiUrl + "getinpersoncities", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data.data);
          setcitiesdropdown(res.data.data);
          // setshowloding(false);
        })
        .catch((err) => {
          alert(err.message);
        });
    };
  
    const formatMonthYear = (date) => {
      const options = { month: "long", year: "numeric" };
      return date.toLocaleDateString("en-US", options);
    };
  
    // Helper function to get the start and end dates of a month
    const getMonthStartEndDates = (date) => {
      const start = new Date(date.getFullYear(), date.getMonth(), 1);
      const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      return {
        startDate: start.toISOString().split("T")[0],
        endDate: end.toISOString().split("T")[0],
      };
    };
  
    const getNextMonths = () => {
      const months = [];
      const currentDate = new Date();
  
      for (let i = 0; i <= 7; i++) {
        const futureDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + i,
          1
        );
        const monthYear = formatMonthYear(futureDate);
        const { startDate, endDate } = getMonthStartEndDates(futureDate);
        var obj = {};
        obj["date"] = startDate + "--" + endDate;
        obj["month"] = monthYear;
        // console.log('startDate >>', startDate);
        // console.log('endDate >>', endDate);
        months.push(obj);
      }
  
      return months;
    };
  
    const openModal = () => {
      setConsultmodalIsOpen(true);
    };
  
    const closeModal = () => {
      setConsultmodalIsOpen(false);
    }
  
    const closeModal1 = () => {
      setmodalIsOpen(false);
    }
  
    useEffect(() => {
      // setselectedcountry()
      // console.log('selectedcountry >>', selectedcountry);
      if (selectedcountry) {
        fetchinpersoncities(selectedcountry);
      }
    }, [selectedcountry]);

    const fetchproductsbyid = async() => {
      setshowloding(true);
        const headers = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        let resp = await axios
          .get(apiUrl + "getallcategories", headers)
          .then((res) => {
            if (res.data.status == "success") {
              // console.log('getallcategories >>', res.data.categorydata);
              var prevdata = res.data.categorydata;
              var newarr = [{
                "categoryname": "All Courses",
                "slug": "all",
                "isshow": true
            }];
              for(var i = 0; i < prevdata?.length; i++) {
                var newobj = prevdata[i];
                newobj['isshow'] = false;
                newarr.push(newobj);
              }
              setcategorydata(newarr);
              setselectedindex(0);
            } else {
              // alert(res.data.message);
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      }

    const handlechangecheckbox = async(index) => {
        setselectedindex(index);
        setFilterOpen(false)
    }

    const handlechangeviewas = async(num) => {
        setviewas(num)
    }

    const fetchcoursesbycategory = async(slug) => {
      setshowloding(true);
        var data = {
            category: slug,
        };
        const headers = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        let resp = await axios
          .post(apiUrl + "getcoursesbycategory", data, headers)
          .then((res) => {
            if (res.data.status == "success") {
             if(res?.data?.data?.length > 0) {
              setresultsdata(res?.data?.data);
             } else {
              setresultsdata([]);
             }
            } else {
             
            }
          }).catch((err) => {
            setshowloding(false);
            alert(err.message);
          });
          setshowloding(false);
    }

    useEffect(() => {
      console.log('selectedindex >>', selectedindex);
      if(categorydata?.length > 0) {
        if(selectedindex) {
          console.log('categoryslug >>>', categorydata[selectedindex])
          fetchcoursesbycategory(categorydata[selectedindex]?.slug);
        } else if (selectedindex == 0) {
          console.log('categoryslug >>>', categorydata[selectedindex]);
          fetchcoursesbycategory(categorydata[selectedindex]?.slug);
        } else {
  
        }
      }
      
      
    }, [selectedindex])

      useEffect(() => {
        fetchproductsbyid();
        fetchcountriesdropdown();
    const nextMonths = getNextMonths();
    console.log("nextMonths >>>", nextMonths);
    setmonthsdropdown(nextMonths);
      }, []);

    return (
      <>
        <Header />
        <div className="breadscrumbg">
          <div className="pagewidth">
            <p className="breadscrumb-pagetitle">Explore Courses</p>
            <p className="breadscrumb-text">
              Where Learning Meets Excellence
            </p>
          </div>
        </div>
        <div className="home-section2">
          <div className="pagewidth">
            <div className="row">
              <div className="col-12 col-md-4 mobhide">
                <div className="allcourses-sidebar">
                  <p className="allcourses-categoryhead">Categories</p>
                  <div className="categorysidebardiv">
                    {categorydata?.length > 0
                      ? categorydata?.map((item, index) => {
                          // console.log("item >>", item);
                          return (
                            <div className="categorysidebardivrow">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onChange={(t) => {handlechangecheckbox(index)}}
                                checked={index == selectedindex ? true : false}
                              ></input>
                              <p className="allcourses-sidebartitle">
                                {item?.categoryname}
                              </p>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8">
                <div className="allcourses-maindiv">
                  <div className="resulttoprow">
                    <p className="resulttext">Showing {resultsdata?.length} total results</p>
                    <div className={viewas == 0 ? "gridicondiv mobhide active" : "mobhide gridicondiv"} onClick={() => {handlechangeviewas(0)}}>
                        <FontAwesomeIcon
                            icon={faTableCellsLarge}
                            className="gridiconstyle"
                        />
                    </div>
                    <div className={viewas == 1 ? "gridicondiv mobhide gridicondiv1 active" : "gridicondiv mobhide gridicondiv1"} onClick={() => {handlechangeviewas(1)}}>
                        <FontAwesomeIcon
                            icon={faList}
                            className="gridiconstyle"
                        />
                    </div>
                    <div className={viewas == 0 ? "gridicondiv deskhide active" : "deskhide gridicondiv"} onClick={() => {setFilterOpen(true)}}>
                        <FontAwesomeIcon
                            icon={faFilter}
                            className="gridiconstyle"
                        />
                    </div>
                  </div>
                  <div className="allcourses-resultsblockdiv">
                    <div className="row">

                      {
                        resultsdata?.length > 0 ?
                        resultsdata?.map((item, index) => {
                          var maxlength = 100;
                          var description = item?.shortdesc?.length > 0 ? item?.shortdesc.slice(0, maxlength) + "..." : item?.shortdesc;
                          var number = Math.floor(1000 + Math.random() * 9000);
                          return (
                            <div key={index} className={viewas == 0 ? "col-12 col-sm-6 col-md-6" : "col-12 col-sm-12 col-md-12"}>
                        <div className="course-card-body">
                          <p className="course-card-title" style={viewas == 0 ? {textAlign: 'center'} : {textAlign: 'left'}}>
                            {item?.title}
                          </p>
                          <div className="desc-maindiv">
                            <div className="icondiv">
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                className="iconstyles"
                              />
                            </div>
                            <p className="course-card-desc">
                            {description}
                            </p>
                          </div>
                          <div className="features-div">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="features1-div">
                                  <FontAwesomeIcon
                                    icon={faUserCheck}
                                    className="iconstyles"
                                  />
                                  <p className="userchecktext">
                                  {item?.enroll ? item?.enroll : number}+ Enrolled <br />Already
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="features1-div">
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    className="iconstyles"
                                  />
                                  <p className="userchecktext">Weekdays / Weekends</p>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="features1-div">
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className="iconstyles"
                                  />
                                  <p className="userchecktext">{item?.googlerating} / 5</p>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="features1-div">
                                  <FontAwesomeIcon
                                    icon={faDesktop}
                                    className="iconstyles"
                                  />
                                  <p className="userchecktext">
                                    Live Online Class
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr className="course-card-hr" />
                          <div className="banner-btn-div allcourses-banner-btn-div">
                            <Link to={"/courses/"+item?.category+"/"+item?.slug} className="course-btn">
                              <p>View Course</p>
                            </Link>
                            <div onClick={() => {selectedcoursefunc(item?.slug)}} className="course-btn course-btn1" style={viewas == 0 ? {marginLeft: 'auto', cursor: 'pointer'} : {marginLeft: 20, cursor: 'pointer'}}>
                              <p>View Schedule</p>
                            </div>
                          </div>
                        </div>
                      </div>
                          )
                        }) : 
                        showloading ?
                        <div>
                          <Spinner color='#4fb293' size={25} className='coursesloader' />
                        </div> :
                        <p className="nocoursetoshow">No Courses to show</p>
                      }

                      


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*In Person modals */}

        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal1}
            contentLabel="Example Modal"
            id="inpersommodalid"
          >
            <div className="inpersonmodal">
              <div className="modalheader">
                <p className="modaltitle">
                  Batches For {selectedcoursenameRef.current}
                </p>
                <div
                  className="modalclosediv"
                  onClick={() => {
                    closeModal1();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="coursecloseicons"
                  />
                </div>
              </div>
              <div className="schedulerow">
                <div className="schedulecheckboxdiv">
                  <input type="checkbox" 
                  onChange={(t) => {handlechangecheckboxCourse(t, 0)}}
                  checked={schedulecheck == 0 ? true : false}
                   />
                  <p className="schedule-inpersontitle">In-Person Classroom Training</p>
                </div>
                <div className="schedulecheckboxdiv">
                  <input type="checkbox"  
                  onChange={(t) => {handlechangecheckboxCourse(t, 1)}}
                  checked={schedulecheck == 1 ? true : false}
                  />
                  <p className="schedule-inpersontitle">Live Online Training</p>
                </div>
              </div>
              <div className="modalbody">
                <div className="modalbatchfiltersection">
                  <div className="row">
                    <div className="col-md-3">
                      <p for="sel1" className="form-label modalformlabel">
                        Select Country
                      </p>
                      <select
                        className="form-select"
                        id="sel1"
                        name="sellist1"
                        value={selectedcountry}
                        onChange={(t) => {
                          setselectedcountry(t.target.value);
                        }}
                      >
                        <option disabled selected>
                          Select Country
                        </option>
                        {countriesdropdown?.length > 0
                          ? countriesdropdown?.map((item, index) => {
                              return (
                                <option value={item?.country}>
                                  {item?.country}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                    {
                      schedulecheck == 0 ?
                      <div className="col-md-3">
                      <p for="sel1" className="form-label modalformlabel">
                        Select City
                      </p>
                      <select
                        className="form-select"
                        id="sel1"
                        name="sellist1"
                        value={selectedcity}
                        onChange={(t) => {
                          setselectedcity(t.target.value);
                        }}
                      >
                        <option disabled selected>
                          Select City
                        </option>
                        {citiesdropdown?.length > 0
                          ? citiesdropdown?.map((item, index) => {
                              return (
                                <option value={item?.city}>{item?.city}</option>
                              );
                            })
                          : null}
                      </select>
                    </div> : null
                    }
                    
                    <div className="col-md-3">
                      <p for="sel1" className="form-label modalformlabel">
                        Select Month
                      </p>
                      <select
                        className="form-select"
                        id="sel1"
                        name="sellist1"
                        value={selectedmonth}
                        onChange={(t) => {
                          setselectedmonth(t.target.value);
                        }}
                      >
                        <option disabled selected>
                          Select Month
                        </option>
                        {monthsdropdown?.length > 0
                          ? monthsdropdown?.map((item, index) => {
                              return (
                                <option value={item.date}>{item.month}</option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <label for="course">&nbsp;</label>
                      {InpersonSearchbtn()}
                    </div>
                  </div>
                  <div className="resultsdiv">
                    <p className="batchesavailablehead">Batches Available</p>
                    { schedulecheck == 0 ?
                    inpersonbatchesdata?.length > 0 ? (
                      inpersonbatchesdata?.map((item, index) => {
                        // console.log('item >>', item);
                        return (
                          <div className="inpersondisplayrow">
                            <div className="row">
                              <div className="col-12 col-md-4">
                                <div className="popupdate">
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    className="popupicons"
                                  />
                                  <p className="popupdatetext">
                                    {item?.startdate} - {item?.enddate}
                                  </p>
                                </div>
                                <div className="popupdate popupcountry">
                                  <p className="popupdatetext popupcountrytext">
                                    Country :
                                  </p>
                                  <p className="popupdatetext popupdatetext1">
                                    {item?.country}
                                  </p>
                                </div>
                              </div>
                              <div className="col-12 col-md-3">
                                <div className="popupdate">
                                  <FontAwesomeIcon
                                    icon={faClock}
                                    className="popupicons"
                                  />
                                  <p className="popupdatetext">
                                    {item?.starttime} - {item?.endtime}
                                  </p>
                                </div>
                                <div className="popupdate popupcountry">
                                  <p className="popupdatetext popupcountrytext">
                                    City :
                                  </p>
                                  <p className="popupdatetext popupdatetext1">
                                    {item?.city}
                                  </p>
                                </div>
                              </div>
                              <div className="col-12 col-md-3">
                                <div className="popupdate">
                                  <FontAwesomeIcon
                                    icon={faDollar}
                                    className="popupicons"
                                  />
                                  <p
                                    className="popupdatetext"
                                    style={{ marginLeft: 5 }}
                                  >
                                    USD <del>{item?.price}</del>
                                    <span className="popupofferprice">
                                      {item?.offerprice}
                                    </span>
                                  </p>
                                </div>
                                <div className="popupdate popupcountry">
                                  <p className="popupdatetext popupcountrytext">
                                    {item?.weektype}
                                  </p>
                                </div>
                              </div>
                              <div
                                className="col-12 col-md-2"
                                onClick={() => {
                                  handleinpersonclassroomclick(item);
                                }}
                              >
                                <button
                                  href="cart"
                                  className="form-control btn btn-success popupenrollbtn"
                                >
                                  Enroll
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : shownobatchesavailable ? (
                      <p className="nobatchestext">
                        No batches available for selected month.
                      </p>
                    ) : null 
                  :
                  liveonlinebatchesdata?.length > 0 ? (
                    liveonlinebatchesdata?.map((item, index) => {
                      // console.log('item >>', item);
                      return (
                        <div className="inpersondisplayrow">
                          <div className="row">
                            <div className="col-12 col-md-4">
                              <div className="popupdate">
                                <FontAwesomeIcon
                                  icon={faCalendar}
                                  className="popupicons"
                                />
                                <p className="popupdatetext">
                                  {item?.startdate} - {item?.enddate}
                                </p>
                              </div>
                              <div className="popupdate popupcountry">
                                <p className="popupdatetext popupcountrytext">
                                  Country :
                                </p>
                                <p className="popupdatetext popupdatetext1">
                                  {item?.country}
                                </p>
                              </div>
                            </div>
                            <div className="col-12 col-md-3">
                              <div className="popupdate">
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className="popupicons"
                                />
                                <p className="popupdatetext">
                                  {item?.starttime} - {item?.endtime}
                                </p>
                              </div>
                              {/* <div className="popupdate popupcountry">
                          <p className="popupdatetext popupcountrytext">
                          City :
                          </p>
                          <p className="popupdatetext popupdatetext1">
                          {item?.city}
                          </p>
                        </div> */}
                            </div>
                            <div className="col-12 col-md-3">
                              <div className="popupdate">
                                <FontAwesomeIcon
                                  icon={faDollar}
                                  className="popupicons"
                                />
                                <p
                                  className="popupdatetext"
                                  style={{ marginLeft: 5 }}
                                >
                                  USD <del>{item?.price}</del>
                                  <span className="popupofferprice">
                                    {item?.offerprice}
                                  </span>
                                </p>
                              </div>
                              <div className="popupdate popupcountry">
                                <p className="popupdatetext popupcountrytext">
                                  {item?.weektype}
                                </p>
                              </div>
                            </div>
                            <div
                              className="col-12 col-md-2"
                              onClick={() => {
                                handleliveonlineclassroom(item);
                              }}
                            >
                              <button
                                href="cart"
                                className="form-control btn btn-success popupenrollbtn"
                              >
                                Enroll
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : shownobatchesavailable ? (
                    <p className="nobatchestext">
                      No batches available for selected month.
                    </p>
                  ) : null
                  }
                  </div>
                </div>
              </div>
            </div>
          </Modal>


          <Drawer
        className="mobproductsfinder"
        open={Filteropen}
        placement="right"
        onClick={() => setFilterOpen(false)}
        onClose={() => setFilterOpen(false)}
        backdrop={true}
        size={"xs"}
      >
        <Drawer.Header>
          {/* <p className="unreadhead">unread message</p> */}
        </Drawer.Header>
        <Drawer.Body>
          {/* <Placeholder.Paragraph rows={8} /> */}
          <div className="mobproductsfinder-bodydiv">
            <div className="allcourses-sidebar">
                  <p className="allcourses-categoryhead">Select Categories</p>
                  <div className="categorysidebardiv">
                    {categorydata?.length > 0
                      ? categorydata?.map((item, index) => {
                          // console.log("item >>", item);
                          return (
                            <div className="categorysidebardivrow">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onChange={(t) => {handlechangecheckbox(index)}}
                                checked={index == selectedindex ? true : false}
                              ></input>
                              <p className="allcourses-sidebartitle">
                                {item?.categoryname}
                              </p>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
            </div>
        </Drawer.Body>
      </Drawer>
      </>
    );
   
}

export default AllCourses