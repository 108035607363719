import React, {useCallback, useEffect, useRef, useState} from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlagUsa,
  faPhone,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { faCanadianMapleLeaf } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { CourseCard } from "../components/CourseCard";
import img1 from '../assets/corporate/1.png';
import client1 from '../assets/corporate/client1.png';
import client2 from '../assets/corporate/client2.png';
import client3 from '../assets/corporate/client3.png';
import client4 from '../assets/corporate/client4.png';
import { Imprints } from "../components/Imprints";
import { Carousel } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import {
  faCalendar,
  faClock,
  faDollar,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import Select from 'react-select';
import Swal from 'sweetalert2';
import Spinner from "react-activity/dist/Spinner";
import axios from 'axios';
import { apiUrl } from "../config/Config";
import { useNavigate } from "react-router-dom";

const CorporateTraining = () => {
  const [consultmodalIsOpen, setConsultmodalIsOpen] = useState(false);
  const [showloading, setshowloding] = useState(false);

  const [fullname, setfullname] = useState("");
  const [email, setemail] = useState("");
  const [number, setnumber] = useState("");
  const [selectedcourse, setselectedcourse] = useState({});
  const [message, setmessage] = useState("");
  const [privacycheck, setprivacycheck] = useState(false);
  const [swalProps, setSwalProps] = useState({});
  const [courses, setcourses] = useState([]);

  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [selectedcoursename, setselectedcoursename] = useState(null);
  const [selectedcountry, setselectedcountry] = useState(null);
  const [countriesdropdown, setcountriesdropdown] = useState([]);
  const [selectedcity, setselectedcity] = useState(null);
  const [citiesdropdown, setcitiesdropdown] = useState([]);
  const [selectedmonth, setselectedmonth] = useState(null);
  const [monthsdropdown, setmonthsdropdown] = useState([]);
  const [inpersonbatchesdata, setinpersonbatchesdata] = useState([]);
  const [shownobatchesavailable, setshownobatchesavailable] = useState(false);

  const [liveonlinebatchesdata, setliveonlinebatchesdata] = useState([]);

  const [schedulecheck, setschedulecheck] = useState(0);



  const selectedcoursenameRef = useRef(null);
  selectedcoursenameRef.current = selectedcoursename;

  const navigate = useNavigate();

  const InpersonSearchbtn = () => {
    var diablestatus = true;
    if (selectedcountry && selectedmonth) {
      if(schedulecheck == 0) {
        if(selectedcity) {
          diablestatus = false;
        } else {
          diablestatus = true;
        }
      } else {
        diablestatus = false;
      }
      
    } else {
      diablestatus = true;
    }
    return (
      <button
        type="button"
        className="btn btn-primary modalsearchbtn schedulecheckmodalsearchbtn"
        onClick={() => {
          if(schedulecheck == 0) {
            filterinpersonbatches();
          } else {
            filterliveOnlinebatches();
          }
          }}
        disabled={diablestatus}
      >
        Search
      </button>
    );
  };

  const filterinpersonbatches = async () => {
    setshownobatchesavailable(false);
    var splitdate = selectedmonth?.split("--");
    // console.log('splitdate >>', splitdate);
    var data = JSON.stringify({
      country: selectedcountry,
      city: selectedcity,
      fromdate: splitdate[0],
      enddate: splitdate[1],
      // course: courseslug,
      course: selectedcoursenameRef?.current,
    });
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .post(apiUrl + "getinpersonbatches", data, headers)
      .then((res) => {
        console.log("res >>>", res.data.data);
        setinpersonbatchesdata(res.data.data);
        if (res.data.data?.length == 0) {
          setshownobatchesavailable(true);
        } else {
          setshownobatchesavailable(false);
        }
        // setcitiesdropdown(res.data.data);
        setshowloding(false);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const filterliveOnlinebatches = async () => {
    setshownobatchesavailable(false);
    var splitdate = selectedmonth?.split("--");
    var data = JSON.stringify({
      country: selectedcountry,
      fromdate: splitdate[0],
      enddate: splitdate[1],
      // course: courseslug,
      course: selectedcoursenameRef?.current,
    });
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .post(apiUrl + "getliveonlinebatches", data, headers)
      .then((res) => {
        console.log("res >>>", res.data.data);
        setliveonlinebatchesdata(res.data.data);
        if (res.data.data?.length == 0) {
          setshownobatchesavailable(true);
        } else {
          setshownobatchesavailable(false);
        }
        setshowloding(false);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const handleinpersonclassroomclick = (data) => {
    console.log("data >>", data);
    closeModal1();
    navigate("/cart", {
      state: {
        categoryslug: data?.category,
        courseslug: selectedcoursenameRef?.current,
        coursename: selectedcoursenameRef?.current,
        price: data?.offerprice,
        type: "In Person Classroom",
        country: data?.country,
        city: data?.city,
        startdate: data?.startdate,
        enddate: data?.enddate,
        starttime: data?.starttime,
        endtime: data?.endtime,
        weektype: data?.weektype,
      },
    });
  };

  const handleliveonlineclassroom = (data) => {
    console.log("handleliveonlineclassroom data >>", data);
    closeModal1();
    navigate("/cart", {
      state: {
        categoryslug: data?.category,
        courseslug: selectedcoursenameRef?.current,
        coursename: selectedcoursenameRef?.current,
        price: data?.offerprice,
        type: "Live Online Classroom",
        country: data?.country,
        city: data?.city,
        startdate: data?.startdate,
        enddate: data?.enddate,
        starttime: data?.starttime,
        endtime: data?.endtime,
        weektype: data?.weektype,
      },
    });
  };

  const selectedcoursefunc = useCallback((t) => {
    console.log('selectedcoursefunc t >>', t)
    setselectedcoursename(t);
    setmodalIsOpen(true);
    setselectedcountry(null);
    setselectedcity(null);
    setselectedmonth(null);
    setinpersonbatchesdata([]);
    setliveonlinebatchesdata([]);
    setshownobatchesavailable(false);
  }, [])

  const handlechangecheckbox = (status, num) => {
    if(status) {
      setschedulecheck(num);
    }
  }

  const fetchcountriesdropdown = async() => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .get(apiUrl + "getschedulecountries", headers)
      .then((res) => {
        setcountriesdropdown(res.data.countries);
        setshowloding(false);
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  const fetchinpersoncities = async (country) => {
    var data = JSON.stringify({
      country: country,
    });
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .post(apiUrl + "getinpersoncities", data, headers)
      .then((res) => {
        // console.log('res >>>', res.data.data);
        setcitiesdropdown(res.data.data);
        setshowloding(false);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const formatMonthYear = (date) => {
    const options = { month: "long", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  // Helper function to get the start and end dates of a month
  const getMonthStartEndDates = (date) => {
    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return {
      startDate: start.toISOString().split("T")[0],
      endDate: end.toISOString().split("T")[0],
    };
  };

  const getNextMonths = () => {
    const months = [];
    const currentDate = new Date();

    for (let i = 0; i <= 7; i++) {
      const futureDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + i,
        1
      );
      const monthYear = formatMonthYear(futureDate);
      const { startDate, endDate } = getMonthStartEndDates(futureDate);
      var obj = {};
      obj["date"] = startDate + "--" + endDate;
      obj["month"] = monthYear;
      // console.log('startDate >>', startDate);
      // console.log('endDate >>', endDate);
      months.push(obj);
    }

    return months;
  };

  const openModal = () => {
    setConsultmodalIsOpen(true);
  };

  const closeModal = () => {
    setConsultmodalIsOpen(false);
  }

  const closeModal1 = () => {
    setmodalIsOpen(false);
  }

  const handlesubmitconsultation = async() => {
    if(fullname && email && number ) {
      setshowloding(true);
        var data = {
            fullname: fullname,
            email: email,
            number: number,
            message: message
        };
        const headers = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        let resp = await axios
          .post(apiUrl + "getconsultationsubmit", data, headers)
          .then((res) => {
            console.log('res >>>', res.data.data)
            if (res.data.status == "success") {
              Swal.fire({
                title: 'Thank You!',
                text: 'One of our representative will get back to you.',
                icon: 'success',
                customClass: {
                  icon: 'custom-swal-icon' // Apply the custom class
                },
                width: '400px',
                confirmButtonText: 'Ok'
              });
              setfullname("");
              setemail("");
              setnumber("");
              setmessage("");
              closeModal();
            } else {
              Swal.fire({
                title: 'Not Submitted!!',
                text: 'Something went wrong please try again',
                icon: 'error',
                customClass: {
                  icon: 'custom-swal-icon' // Apply the custom class
                },
                width: '400px',
                confirmButtonText: 'Ok'
              });
            }
          }).catch((err) => {
            setshowloding(false);
            alert(err.message);
          });
          setshowloding(false);
      } else {
      alert('Fill all required details.')
    }
  }

  const fetchhomepagecourses = async() => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .get(apiUrl + "gethomepagecourses", headers)
      .then((res) => {
        // console.log('res >>>', res?.data?.data)
        if(res?.data?.data?.length > 0) {
          setcourses(res?.data?.data);
        }
      }).catch((err) => {
      });
  }

  useEffect(() => {
    // setselectedcountry()
    // console.log('selectedcountry >>', selectedcountry);
    if (selectedcountry) {
      fetchinpersoncities(selectedcountry);
    }
  }, [selectedcountry]);

  useEffect(() => {
    fetchhomepagecourses();
    fetchcountriesdropdown();
    const nextMonths = getNextMonths();
    console.log("nextMonths >>>", nextMonths);
    setmonthsdropdown(nextMonths);
  }, [])
  return (
    <>
      <Header />
      <div className="breadscrumbg">
        <div className="pagewidth">
          <p className="breadscrumb-pagetitle">Upskill Your Team</p>
          <div className="row">
            <div className="col-md-8">
              <p className="breadscrumb-text corporate-subtext">
                Empowering Organizations with Future Ready Corporate Training Solutions
              </p>
              <p className="corporate-breadscrumb-desc">Prepare to take the next step in your career with the leading training provider globally. Begin your immersive learning journey with us.</p>
              <img src={img1} className="corporate-img1 deskhide" />
              <div className="banner-btn-div">
                  <Link to={"/all-courses"} className="course-btn">
                    <p>View Course</p>
                  </Link>
                  <Link className="course-btn course-btn1" onClick={() => {
                  openModal();
                }}>
                    <p>Book Consultation</p>
                  </Link>
                </div>
            </div>
            <div className="col-md-4">
                <img src={img1} className="corporate-img1 mobhide" />
            </div>
          </div>
        </div>
      </div>
      <div className="home-section2 corporate-section1">
        <div className="pagewidth">
            <div className="row">
                <div className="col-md-7">
                    <p className="section-head corporate-section1-head">Empowering Organizations with Future-Ready Corporate Training Solutions</p>
                    <p className="choose-description corporate-section1-description">In today’s fiercely competitive landscape, technology evolves at a rapid pace, rendering skills obsolete within a short span of time. Recognizing the critical importance of continuously enhancing the abilities of employees, we are committed to fostering professional growth through our comprehensive corporate training programs. Our tailored training schedules are designed to equip individuals with the latest industry knowledge and skills, empowering them to stay ahead in their respective fields. By prioritizing the ongoing development of your workforce, we help your organization thrive in the dynamic business environment of today.</p>
                </div>
                <div className="col-md-5">
                    <p className="section-head">Our Clients</p>
                    <div className="row clientmaindiv">
                        <div className="col-6 col-md-6">
                            <div className="client-div">
                                <img src={client1} className="client1img" />
                            </div>
                        </div>
                        <div className="col-6 col-md-6">
                            <div className="client-div">
                                <img src={client2} className="client1img" />
                            </div>
                        </div>
                        <div className="col-6 col-md-6">
                            <div className="client-div">
                                <img src={client3} className="client1img" />
                            </div>
                        </div>
                        <div className="col-6 col-md-6">
                            <div className="client-div">
                                <img src={client4} className="client1img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div className="home-section2">
          <div className="pagewidth">
            <p className="section-head">
            Training and Certification Programs for Corporate Teams
            </p>
            <div className="course-card-row row">
              {
                courses?.length > 0 ?
                courses?.map((item, index) => {
                  return(
                    <div className="col-md-4" key={index}>
                      <CourseCard data={item} selectedcourse={selectedcoursefunc} />
                    </div>
                  )
                }) :
                null
              }
              
            </div>
            <div className="course-btn2div">
              <Link to={"/all-courses"} className="course-btn course-btn2">
                <p>View All Courses</p>
              </Link>
            </div>
          </div>
        </div>
        <Imprints />
      <Footer />

      <Modal
          isOpen={consultmodalIsOpen}
          onRequestClose={closeModal}
          id="registermodal"
        >
          <div className="inpersonmodal">
            <div className="modalheader">
              <p className="modaltitle">Get Consultation</p>
              <div
                className="modalclosediv"
                onClick={() => {
                  closeModal();
                }}
              >
                <FontAwesomeIcon icon={faXmark} className="coursecloseicons" />
              </div>
            </div>
            <div className="modalbody">
              <div className="registermodalmaindiv">
                <div className="learner-inputdiv">
                  <p className="ordersummary-course-schedulerowkey">
                    Full Name <span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    value={fullname}
                    onChange={(t) => {
                      setfullname(t.target.value);
                    }}
                    type="text"
                    className="form-control order-summaryinput"
                    placeholder="Enter Full Name"
                  />
                </div>
                <div className="learner-inputdiv">
                  <p className="ordersummary-course-schedulerowkey">
                    Email Address <span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    value={email}
                    onChange={(t) => {
                      setemail(t.target.value);
                    }}
                    type="email"
                    className="form-control order-summaryinput"
                    placeholder="Enter Email Address"
                  />
                </div>
                <div className="learner-inputdiv">
                  <p className="ordersummary-course-schedulerowkey">
                    Mobile Number <span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    value={number}
                    onChange={(t) => {
                      setnumber(t.target.value);
                    }}
                    type="number"
                    className="form-control order-summaryinput"
                    placeholder="Enter Contact Number"
                  />
                </div>
                <div className="learner-inputdiv">
                  <p className="ordersummary-course-schedulerowkey">Message (optional)</p>
                  <textarea
                    style={{ height: 80 }}
                    rows={5}
                    value={message}
                    onChange={(t) => {
                      setmessage(t.target.value);
                    }}
                    type="text"
                    className="form-control order-summaryinput"
                    placeholder="Enter Message"
                  ></textarea>
                </div>
                <div className="registerbtn" onClick={
                  !showloading ?
                  handlesubmitconsultation : null
                } >
                  {
                    showloading ?
                    <Spinner color='#fff' size={15} className='spinnerCenter' /> : 
                    <p>Submit</p>
                  }
                  
                </div>
              </div>
            </div>
          </div>
        </Modal>

       {/*In Person modals */}

       <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal1}
            contentLabel="Example Modal"
            id="inpersommodalid"
          >
            <div className="inpersonmodal">
              <div className="modalheader">
                <p className="modaltitle">
                  Batches For {selectedcoursenameRef.current}
                </p>
                <div
                  className="modalclosediv"
                  onClick={() => {
                    closeModal1();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="coursecloseicons"
                  />
                </div>
              </div>
              <div className="schedulerow">
                <div className="schedulecheckboxdiv">
                  <input type="checkbox" 
                  onChange={(t) => {handlechangecheckbox(t, 0)}}
                  checked={schedulecheck == 0 ? true : false}
                   />
                  <p className="schedule-inpersontitle">In-Person Classroom Training</p>
                </div>
                <div className="schedulecheckboxdiv">
                  <input type="checkbox"  
                  onChange={(t) => {handlechangecheckbox(t, 1)}}
                  checked={schedulecheck == 1 ? true : false}
                  />
                  <p className="schedule-inpersontitle">Live Online Training</p>
                </div>
              </div>
              <div className="modalbody">
                <div className="modalbatchfiltersection">
                  <div className="row">
                    <div className="col-md-3">
                      <p for="sel1" className="form-label modalformlabel">
                        Select Country
                      </p>
                      <select
                        className="form-select"
                        id="sel1"
                        name="sellist1"
                        value={selectedcountry}
                        onChange={(t) => {
                          setselectedcountry(t.target.value);
                        }}
                      >
                        <option disabled selected>
                          Select Country
                        </option>
                        {countriesdropdown?.length > 0
                          ? countriesdropdown?.map((item, index) => {
                              return (
                                <option value={item?.country}>
                                  {item?.country}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                    {
                      schedulecheck == 0 ?
                      <div className="col-md-3">
                      <p for="sel1" className="form-label modalformlabel">
                        Select City
                      </p>
                      <select
                        className="form-select"
                        id="sel1"
                        name="sellist1"
                        value={selectedcity}
                        onChange={(t) => {
                          setselectedcity(t.target.value);
                        }}
                      >
                        <option disabled selected>
                          Select City
                        </option>
                        {citiesdropdown?.length > 0
                          ? citiesdropdown?.map((item, index) => {
                              return (
                                <option value={item?.city}>{item?.city}</option>
                              );
                            })
                          : null}
                      </select>
                    </div> : null
                    }
                    
                    <div className="col-md-3">
                      <p for="sel1" className="form-label modalformlabel">
                        Select Month
                      </p>
                      <select
                        className="form-select"
                        id="sel1"
                        name="sellist1"
                        value={selectedmonth}
                        onChange={(t) => {
                          setselectedmonth(t.target.value);
                        }}
                      >
                        <option disabled selected>
                          Select Month
                        </option>
                        {monthsdropdown?.length > 0
                          ? monthsdropdown?.map((item, index) => {
                              return (
                                <option value={item.date}>{item.month}</option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <label for="course">&nbsp;</label>
                      {InpersonSearchbtn()}
                    </div>
                  </div>
                  <div className="resultsdiv">
                    <p className="batchesavailablehead">Batches Available</p>
                    { schedulecheck == 0 ?
                    inpersonbatchesdata?.length > 0 ? (
                      inpersonbatchesdata?.map((item, index) => {
                        // console.log('item >>', item);
                        return (
                          <div className="inpersondisplayrow">
                            <div className="row">
                              <div className="col-12 col-md-4">
                                <div className="popupdate">
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    className="popupicons"
                                  />
                                  <p className="popupdatetext">
                                    {item?.startdate} - {item?.enddate}
                                  </p>
                                </div>
                                <div className="popupdate popupcountry">
                                  <p className="popupdatetext popupcountrytext">
                                    Country :
                                  </p>
                                  <p className="popupdatetext popupdatetext1">
                                    {item?.country}
                                  </p>
                                </div>
                              </div>
                              <div className="col-12 col-md-3">
                                <div className="popupdate">
                                  <FontAwesomeIcon
                                    icon={faClock}
                                    className="popupicons"
                                  />
                                  <p className="popupdatetext">
                                    {item?.starttime} - {item?.endtime}
                                  </p>
                                </div>
                                <div className="popupdate popupcountry">
                                  <p className="popupdatetext popupcountrytext">
                                    City :
                                  </p>
                                  <p className="popupdatetext popupdatetext1">
                                    {item?.city}
                                  </p>
                                </div>
                              </div>
                              <div className="col-12 col-md-3">
                                <div className="popupdate">
                                  <FontAwesomeIcon
                                    icon={faDollar}
                                    className="popupicons"
                                  />
                                  <p
                                    className="popupdatetext"
                                    style={{ marginLeft: 5 }}
                                  >
                                    USD <del>{item?.price}</del>
                                    <span className="popupofferprice">
                                      {item?.offerprice}
                                    </span>
                                  </p>
                                </div>
                                <div className="popupdate popupcountry">
                                  <p className="popupdatetext popupcountrytext">
                                    {item?.weektype}
                                  </p>
                                </div>
                              </div>
                              <div
                                className="col-12 col-md-2"
                                onClick={() => {
                                  handleinpersonclassroomclick(item);
                                }}
                              >
                                <button
                                  href="cart"
                                  className="form-control btn btn-success popupenrollbtn"
                                >
                                  Enroll
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : shownobatchesavailable ? (
                      <p className="nobatchestext">
                        No batches available for selected month.
                      </p>
                    ) : null 
                  :
                  liveonlinebatchesdata?.length > 0 ? (
                    liveonlinebatchesdata?.map((item, index) => {
                      // console.log('item >>', item);
                      return (
                        <div className="inpersondisplayrow">
                          <div className="row">
                            <div className="col-12 col-md-4">
                              <div className="popupdate">
                                <FontAwesomeIcon
                                  icon={faCalendar}
                                  className="popupicons"
                                />
                                <p className="popupdatetext">
                                  {item?.startdate} - {item?.enddate}
                                </p>
                              </div>
                              <div className="popupdate popupcountry">
                                <p className="popupdatetext popupcountrytext">
                                  Country :
                                </p>
                                <p className="popupdatetext popupdatetext1">
                                  {item?.country}
                                </p>
                              </div>
                            </div>
                            <div className="col-12 col-md-3">
                              <div className="popupdate">
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className="popupicons"
                                />
                                <p className="popupdatetext">
                                  {item?.starttime} - {item?.endtime}
                                </p>
                              </div>
                              {/* <div className="popupdate popupcountry">
                          <p className="popupdatetext popupcountrytext">
                          City :
                          </p>
                          <p className="popupdatetext popupdatetext1">
                          {item?.city}
                          </p>
                        </div> */}
                            </div>
                            <div className="col-12 col-md-3">
                              <div className="popupdate">
                                <FontAwesomeIcon
                                  icon={faDollar}
                                  className="popupicons"
                                />
                                <p
                                  className="popupdatetext"
                                  style={{ marginLeft: 5 }}
                                >
                                  USD <del>{item?.price}</del>
                                  <span className="popupofferprice">
                                    {item?.offerprice}
                                  </span>
                                </p>
                              </div>
                              <div className="popupdate popupcountry">
                                <p className="popupdatetext popupcountrytext">
                                  {item?.weektype}
                                </p>
                              </div>
                            </div>
                            <div
                              className="col-12 col-md-2"
                              onClick={() => {
                                handleliveonlineclassroom(item);
                              }}
                            >
                              <button
                                href="cart"
                                className="form-control btn btn-success popupenrollbtn"
                              >
                                Enroll
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : shownobatchesavailable ? (
                    <p className="nobatchestext">
                      No batches available for selected month.
                    </p>
                  ) : null
                  }
                  </div>
                </div>
              </div>
            </div>
          </Modal>
    </>
  );
};

export default CorporateTraining;
