import React, { useEffect, useState,useCallback,
    useRef,
    StrictMode, } from "react";
  import Sidebar from "../../components/Sidebar";
  import { useSelector, useDispatch } from "react-redux";
  import { Link, useNavigate } from "react-router-dom";
  import axios from "axios";
  import { apiUrl } from "../../config/Config";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
  import { Button, Modal } from "react-bootstrap";
  import "bootstrap/dist/css/bootstrap.min.css";
  import Overlay from "../../components/Overlay";
  import { useMemo } from 'react';
  import { AgGridReact } from "@ag-grid-community/react";
  import "@ag-grid-community/styles/ag-grid.css";
  import "@ag-grid-community/styles/ag-theme-quartz.css";
  import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
  import { ModuleRegistry } from "@ag-grid-community/core";
  ModuleRegistry.registerModules([ClientSideRowModelModule]);
  
  const Orders = () => {
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const [showloding, setshowloding] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [colDefs, setColDefs] = useState([]);
    const paginationPageSizeSelector = useMemo(() => {
      return [10, 20, 50, 100];
    }, []);
  
    const defaultColDef = {
      flex: 1,
    };
  
    const clickhandle = () =>  {
      console.log('Mission Launched')
    }
    
    const fetchallcourses = async () => {
      setshowloding(true);
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .get(apiUrl + "getallcheckouts", headers)
        .then((res) => {
          if (res.data.status == "success") {
            console.log('getallcheckouts >>', res.data);
            var prevdata = res.data.data;
            if(prevdata?.length > 0) {
              var columnsarr = [];
              var hidecolumns = ['id', 'created_at', 'updated_at', 'sort', 'status','category', 'cartid', 'cartdata', 'paymentid', 'mode'];
              var hidefilters = [];
              var columns = Object.keys(prevdata[0]);
              for(var i = 0; i < columns?.length;i++) {
                if(!hidecolumns.includes(columns[i])) {
                  var obj = {};
                  obj.field = columns[i];
                  // obj.width = 500;
                  if(!hidefilters.includes(columns[i])) {
                    obj.filter = true;
                    obj.filterParams = {
                        filterOptions: ["contains"],
                        maxNumConditions: 1,
                    };
                  }
                  console.log('obj >>', obj)
                  columnsarr.push(obj);
                }
              }
              //delete
              // var deleteobj = 
              // {
              //   field: "delete",
              //   headerName: "Delete",
              //   cellRenderer: (props) => {
                  
              //     return (
              //       <div
              //             onClick={() => {
              //               deletecourse(props?.data?.id);
              //               // console.log('props >>', props?.data);
              //             }}
              //             style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
              //           >
              //             <FontAwesomeIcon
              //               icon={faTrash}
              //               className="sidebaricons"
              //               style={{ color: "red" }}
              //             />
              //           </div>
              //     )
              //   },
              //   cellRendererParams: {
              //     onClick: clickhandle,
              //   },
              // }
              // columnsarr.push(deleteobj);
              setColDefs(columnsarr);
              setRowData(prevdata);
            }
            
            // setcoursesdata(res.data.data);
          } else {
            // alert(res.data.message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
        setshowloding(false);
    };
    const deletecourse = async (id) => {
      const result = window.confirm("Are you sure you want to delete this enquiry ?");
      if (result) {
        var data = JSON.stringify({
          id: id,
        });
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .post(apiUrl + "deletegetquotationrequest", data, headers)
          .then((res) => {
            if (res.data.status == "success") {
              fetchallcourses();
            } else {
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      } else {
        // User clicked Cancel or closed the dialog
        console.log("User clicked Cancel");
      }
    };
  
   
    useEffect(() => {
      if (userdata && Object.keys(userdata).length > 0) {
        //   fetchdata();
        fetchallcourses();
      } else {
        navigate("/newadminlogin");
      }
    }, []);
    return (
      <div className="HomeMain">
          <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar name={"Admin"} />
        </div>
        <div className="main-layout">
          <div className="mainDiv">
            <p className="oneplaceHead">ORDERS</p>
            <br />
            <br />
  
            <div
        className={
          "ag-theme-quartz"
        }
        style={{  height: 550 }}
      >
        <AgGridReact
        // modules={AllModules}
          rowData={rowData}
          columnDefs={colDefs}
          // defaultColDef={defaultColDef}
          suppressRowClickSelection={true}
          // suppressHorizontalScroll={true}
          groupSelectsChildren={true}
          rowSelection={"multiple"}
          pagination={true}
          paginationPageSize={10}
          paginationPageSizeSelector={paginationPageSizeSelector}
          // onGridReady={onGridReady}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default Orders;
  