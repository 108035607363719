import React, {useCallback, useEffect, useRef, useState} from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Imprints } from "../components/Imprints";
import { Link } from "react-router-dom";
import { CourseCard } from "../components/CourseCard";
import choose1 from "../assets/choose/choose1.png";
import choose2 from "../assets/choose/choose2.png";
import choose3 from "../assets/choose/choose3.png";
import trainer1 from "../assets/trainers/1.png";
import trainer2 from "../assets/trainers/2.jpg";
import trainer3 from "../assets/trainers/3.jpg";
import trainer4 from "../assets/trainers/4.jpeg";
import trainer5 from "../assets/trainers/5.jpg";
import trainer6 from "../assets/trainers/6.jpeg";
import { Carousel } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faClock,
  faDollar,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import Select from 'react-select';
import Swal from 'sweetalert2';
import Spinner from "react-activity/dist/Spinner";
import axios from 'axios';
import { apiUrl } from "../config/Config";
import { useNavigate } from "react-router-dom";


const Home = () => {
  const [consultmodalIsOpen, setConsultmodalIsOpen] = useState(false);
  const [showloading, setshowloding] = useState(false);

  const [fullname, setfullname] = useState("");
  const [email, setemail] = useState("");
  const [number, setnumber] = useState("");
  const [selectedcourse, setselectedcourse] = useState({});
  const [message, setmessage] = useState("");
  const [privacycheck, setprivacycheck] = useState(false);
  const [swalProps, setSwalProps] = useState({});
  const [courses, setcourses] = useState([]);

  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [selectedcoursename, setselectedcoursename] = useState(null);
  const [selectedcountry, setselectedcountry] = useState(null);
  const [countriesdropdown, setcountriesdropdown] = useState([]);
  const [selectedcity, setselectedcity] = useState(null);
  const [citiesdropdown, setcitiesdropdown] = useState([]);
  const [selectedmonth, setselectedmonth] = useState(null);
  const [monthsdropdown, setmonthsdropdown] = useState([]);
  const [inpersonbatchesdata, setinpersonbatchesdata] = useState([]);
  const [shownobatchesavailable, setshownobatchesavailable] = useState(false);

  const [liveonlinebatchesdata, setliveonlinebatchesdata] = useState([]);

  const [schedulecheck, setschedulecheck] = useState(0);



  const selectedcoursenameRef = useRef(null);
  selectedcoursenameRef.current = selectedcoursename;

  const navigate = useNavigate();

  const InpersonSearchbtn = () => {
    var diablestatus = true;
    if (selectedcountry && selectedmonth) {
      if(schedulecheck == 0) {
        if(selectedcity) {
          diablestatus = false;
        } else {
          diablestatus = true;
        }
      } else {
        diablestatus = false;
      }
      
    } else {
      diablestatus = true;
    }
    return (
      <button
        type="button"
        className="btn btn-primary modalsearchbtn schedulecheckmodalsearchbtn"
        onClick={() => {
          if(schedulecheck == 0) {
            filterinpersonbatches();
          } else {
            filterliveOnlinebatches();
          }
          }}
        disabled={diablestatus}
      >
        Search
      </button>
    );
  };

  const filterinpersonbatches = async () => {
    setshownobatchesavailable(false);
    var splitdate = selectedmonth?.split("--");
    // console.log('splitdate >>', splitdate);
    var data = JSON.stringify({
      country: selectedcountry,
      city: selectedcity,
      fromdate: splitdate[0],
      enddate: splitdate[1],
      // course: courseslug,
      course: selectedcoursenameRef?.current,
    });
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .post(apiUrl + "getinpersonbatches", data, headers)
      .then((res) => {
        console.log("res >>>", res.data.data);
        setinpersonbatchesdata(res.data.data);
        if (res.data.data?.length == 0) {
          setshownobatchesavailable(true);
        } else {
          setshownobatchesavailable(false);
        }
        // setcitiesdropdown(res.data.data);
        setshowloding(false);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const filterliveOnlinebatches = async () => {
    setshownobatchesavailable(false);
    var splitdate = selectedmonth?.split("--");
    var data = JSON.stringify({
      country: selectedcountry,
      fromdate: splitdate[0],
      enddate: splitdate[1],
      // course: courseslug,
      course: selectedcoursenameRef?.current,
    });
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .post(apiUrl + "getliveonlinebatches", data, headers)
      .then((res) => {
        console.log("res >>>", res.data.data);
        setliveonlinebatchesdata(res.data.data);
        if (res.data.data?.length == 0) {
          setshownobatchesavailable(true);
        } else {
          setshownobatchesavailable(false);
        }
        setshowloding(false);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const handleinpersonclassroomclick = (data) => {
    console.log("data >>", data);
    closeModal1();
    navigate("/cart", {
      state: {
        categoryslug: data?.category,
        courseslug: selectedcoursenameRef?.current,
        coursename: selectedcoursenameRef?.current,
        price: data?.offerprice,
        type: "In Person Classroom",
        country: data?.country,
        city: data?.city,
        startdate: data?.startdate,
        enddate: data?.enddate,
        starttime: data?.starttime,
        endtime: data?.endtime,
        weektype: data?.weektype,
      },
    });
  };

  const handleliveonlineclassroom = (data) => {
    console.log("handleliveonlineclassroom data >>", data);
    closeModal1();
    navigate("/cart", {
      state: {
        categoryslug: data?.category,
        courseslug: selectedcoursenameRef?.current,
        coursename: selectedcoursenameRef?.current,
        price: data?.offerprice,
        type: "Live Online Classroom",
        country: data?.country,
        city: data?.city,
        startdate: data?.startdate,
        enddate: data?.enddate,
        starttime: data?.starttime,
        endtime: data?.endtime,
        weektype: data?.weektype,
      },
    });
  };

  const selectedcoursefunc = useCallback((t) => {
    console.log('selectedcoursefunc t >>', t)
    setselectedcoursename(t);
    setmodalIsOpen(true);
    setselectedcountry(null);
    setselectedcity(null);
    setselectedmonth(null);
    setinpersonbatchesdata([]);
    setliveonlinebatchesdata([]);
    setshownobatchesavailable(false);
  }, [])

  const handlechangecheckbox = (status, num) => {
    if(status) {
      setschedulecheck(num);
    }
  }

  const fetchcountriesdropdown = async() => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .get(apiUrl + "getschedulecountries", headers)
      .then((res) => {
        setcountriesdropdown(res.data.countries);
        setshowloding(false);
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  const fetchinpersoncities = async (country) => {
    var data = JSON.stringify({
      country: country,
    });
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .post(apiUrl + "getinpersoncities", data, headers)
      .then((res) => {
        // console.log('res >>>', res.data.data);
        setcitiesdropdown(res.data.data);
        setshowloding(false);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const formatMonthYear = (date) => {
    const options = { month: "long", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  // Helper function to get the start and end dates of a month
  const getMonthStartEndDates = (date) => {
    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return {
      startDate: start.toISOString().split("T")[0],
      endDate: end.toISOString().split("T")[0],
    };
  };

  const getNextMonths = () => {
    const months = [];
    const currentDate = new Date();

    for (let i = 0; i <= 7; i++) {
      const futureDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + i,
        1
      );
      const monthYear = formatMonthYear(futureDate);
      const { startDate, endDate } = getMonthStartEndDates(futureDate);
      var obj = {};
      obj["date"] = startDate + "--" + endDate;
      obj["month"] = monthYear;
      // console.log('startDate >>', startDate);
      // console.log('endDate >>', endDate);
      months.push(obj);
    }

    return months;
  };

  const openModal = () => {
    setConsultmodalIsOpen(true);
  };

  const closeModal = () => {
    setConsultmodalIsOpen(false);
  }

  const closeModal1 = () => {
    setmodalIsOpen(false);
  }

  const handlesubmitconsultation = async() => {
    if(fullname && email && number ) {
      setshowloding(true);
        var data = {
            fullname: fullname,
            email: email,
            number: number,
            message: message
        };
        const headers = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        let resp = await axios
          .post(apiUrl + "getconsultationsubmit", data, headers)
          .then((res) => {
            console.log('res >>>', res.data.data)
            if (res.data.status == "success") {
              Swal.fire({
                title: 'Thank You!',
                text: 'One of our representative will get back to you.',
                icon: 'success',
                customClass: {
                  icon: 'custom-swal-icon' // Apply the custom class
                },
                width: '400px',
                confirmButtonText: 'Ok'
              });
              setfullname("");
              setemail("");
              setnumber("");
              setmessage("");
              closeModal();
            } else {
              Swal.fire({
                title: 'Not Submitted!!',
                text: 'Something went wrong please try again',
                icon: 'error',
                customClass: {
                  icon: 'custom-swal-icon' // Apply the custom class
                },
                width: '400px',
                confirmButtonText: 'Ok'
              });
            }
          }).catch((err) => {
            setshowloding(false);
            alert(err.message);
          });
          setshowloding(false);
      } else {
      alert('Fill all required details.')
    }
  }

  const fetchhomepagecourses = async() => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .get(apiUrl + "gethomepagecourses", headers)
      .then((res) => {
        // console.log('res >>>', res?.data?.data)
        if(res?.data?.data?.length > 0) {
          setcourses(res?.data?.data);
        }
      }).catch((err) => {
      });
  }

  useEffect(() => {
    // setselectedcountry()
    // console.log('selectedcountry >>', selectedcountry);
    if (selectedcountry) {
      fetchinpersoncities(selectedcountry);
    }
  }, [selectedcountry]);

  useEffect(() => {
    fetchhomepagecourses();
    fetchcountriesdropdown();
    const nextMonths = getNextMonths();
    console.log("nextMonths >>>", nextMonths);
    setmonthsdropdown(nextMonths);
  }, [])


    return (
      <>
        <Header />
        <div className="bannermaindiv">
          <div className="banneroverlaycolor">
            <div className="pagewidth">
              <div className="empowering-div">
                <p className="empowering-head">
                  Empowering Professionals for Tomorrow
                </p>
                <p className="empowering-desc">
                  Prepare to take the next step in your career with the leading
                  training provider globally. Begin your immersive learning
                  journey with us.
                </p>
                <div className="banner-btn-div">
                  <Link to={"/all-courses"} className="course-btn">
                    <p>View Courses</p>
                  </Link>
                  <Link className="course-btn course-btn1" onClick={() => {
                  openModal();
                }}>
                    <p>Get Consultation</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-section2">
          <div className="pagewidth">
            <p className="section-head">
              Professional Certification & Training Courses
            </p>
            <div className="course-card-row row">
              {
                courses?.length > 0 ?
                courses?.map((item, index) => {
                  return(
                    <div className="col-md-4" key={index}>
                      <CourseCard data={item} selectedcourse={selectedcoursefunc} />
                    </div>
                  )
                }) :
                null
              }
              
            </div>
            <div className="course-btn2div">
              <Link to={"/all-courses"} className="course-btn course-btn2">
                <p>View All Courses</p>
              </Link>
            </div>
          </div>
        </div>
        <Imprints />
        <div className="home-section4">
          <div className="pagewidth">
            <p className="section-head">Why Upskills Certifications ?</p>
            <div className="why-upskills-div">
              <div className="row">
                <div className="col-md-2">
                  <img src={choose1} className="chooseimg" />
                </div>
                <div className="col-md-10">
                  <p className="choose-title">Career Advancement</p>
                  <p className="choose-description">
                    Our healthy learning environment empowers you to progress at
                    your own momentum without investing in time-consuming and
                    expensive training programs. You'll get the same value and
                    your desired career advancement without making a hole in
                    your pocket. Grab the eyes of potential recruiters and let
                    your resume stand apart from the crowd.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <img src={choose2} className="chooseimg" />
                </div>
                <div className="col-md-10">
                  <p className="choose-title">Cost-effective Solutions</p>
                  <p className="choose-description">
                    Our corporate learning solutions are overall cost-effective
                    for both facilitators and learners as, on the one hand,
                    remote education cuts transportation costs, resources costs,
                    and other crucial costs drastically.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <img src={choose3} className="chooseimg" />
                </div>
                <div className="col-md-10">
                  <p className="choose-title">Learn from Anywhere Anytime</p>
                  <p className="choose-description">
                    Simpliaxis has opened a new door of success for
                    professionals by allowing them to attend web-based career
                    advancement courses. Learners can enroll in corporate
                    learning programs from anywhere globally to assist
                    organizations that run multiple branches simultaneously
                    around the globe.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-section5 light-bg">
          <div className="pagewidth">
            <p className="section-head">Our Trainers</p>
            <Carousel interval={5000} className="trainer-carousel mobhide" >
              <Carousel.Item>
              <div className="trainersmaindiv">
              <div className="trainerdiv">
                <img src={trainer1} className="trainer-img1" />
                <p className="trainer-name">Stephen Mercer</p>
              </div>
              <div className="trainerdiv">
                <img src={trainer2} className="trainer-img1" />
                <p className="trainer-name">Alex Rohrs</p>
              </div>
              <div className="trainerdiv">
                <img src={trainer3} className="trainer-img1" />
                <p className="trainer-name">Todd Williams</p>
              </div>
              <div className="trainerdiv">
                <img src={trainer4} className="trainer-img1" />
                <p className="trainer-name">Eshwar Jakkula</p>
              </div>
            </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="trainersmaindiv">
                <div className="trainerdiv">
                <img src={trainer5} className="trainer-img1" />
                <p className="trainer-name">Christopher Blado</p>
              </div>
              <div className="trainerdiv">
                <img src={trainer6} className="trainer-img1" />
                <p className="trainer-name">Todd Lowe</p>
              </div>
                </div>
              </Carousel.Item>
            </Carousel>
            <Carousel interval={5000} className="trainer-carousel deskhide" >
              <Carousel.Item>
              <div className="trainersmaindiv">
              <div className="trainerdiv">
                <img src={trainer1} className="trainer-img1" />
                <p className="trainer-name">Stephen Mercer</p>
              </div>
              </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="trainersmaindiv">
                <div className="trainerdiv">
                <img src={trainer2} className="trainer-img1" />
                <p className="trainer-name">Alex Rohrs</p>
              </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="trainersmaindiv">
                <div className="trainerdiv">
                <img src={trainer3} className="trainer-img1" />
                <p className="trainer-name">Todd Williams</p>
              </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="trainersmaindiv">
                <div className="trainerdiv">
                <img src={trainer4} className="trainer-img1" />
                <p className="trainer-name">Eshwar Jakkula</p>
              </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="trainersmaindiv">
                <div className="trainerdiv">
                <img src={trainer5} className="trainer-img1" />
                <p className="trainer-name">Christopher Blado</p>
              </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="trainersmaindiv">
                <div className="trainerdiv">
                <img src={trainer6} className="trainer-img1" />
                <p className="trainer-name">Todd Lowe</p>
              </div>
                </div>
              </Carousel.Item>
             
            </Carousel>
          </div>
        </div>
        <div className="home-section6">
          <div className="pagewidth">
            <p className="section-head">Testimonials</p>
            <Carousel interval={5000}>
              <Carousel.Item>
                <div className="testidiv">
                  <p className="testitext">
                    Stephen was very knowledgeable in PMP subject matter. When
                    questions were asked, which sometimes referenced other
                    materials learned or to be learned, he was able to answer
                    them quickly and effectively. Have yet to see how his exam
                    prep questions stand up to the actual test questions, but he
                    at least got me thinking in a different way.
                  </p>
                  <p className="testiname">John Garrity</p>
                  <p className="testicountry">USA</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="testidiv">
                  <p className="testitext">
                  I registered for certified scrum master training through Upskills Certifications and I must say the overall experience was above my expectation. The enrollment process was smooth and the two days training provided all the knowledge and insights required to pass the exam in the first attempt. All Thanks to Upskills Certifications.
                  </p>
                  <p className="testiname">Vinod Kumar</p>
                  <p className="testicountry">India</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="testidiv">
                  <p className="testitext">
                  Stephen Mercer was my PMP instructor and he was amazing. Very detailed-oriented, organized, and clear in his teaching. His examples were helpful and he kept me engaged with the practice tests at the end. He also is clearly invested in his students in passing the exam on first try as he provided insight on the questions to look out for and provided supplemental content to ensure that we are prepared. He definitely goes above and beyond. Highly recommend!
                  </p>
                  <p className="testiname">Jaime</p>
                  <p className="testicountry">USA</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="testidiv">
                  <p className="testitext">
                  Stephen has a tremendous amount of experience in project management, and that came through in the class. The subject matter always felt relevant to both real-life scenarios and what would be needed in order to pass the PMP exam. I enjoyed going through the questions as a group. The information that is provided through the class is very helpful.
                  </p>
                  <p className="testiname">Jason Bourg</p>
                  <p className="testicountry">USA</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="testidiv">
                  <p className="testitext">
                  Stephen was very prompt, professional, and purposeful with his presentations. The overview was smooth and helpful. I’d recommend him any day.
                  </p>
                  <p className="testiname">Chantea Cooper</p>
                  <p className="testicountry">USA</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="testidiv">
                  <p className="testitext">
                  I was skeptical at first. This is a new company, so I was doing a lot of research before choosing to sign up for PMP certification prep class. Well, I was not disappointed. Stephen Mercer is knowledgeable and able to answer questions that the class had effectively. The pace was great. I learned a lot from this 4-day online instructor led class.
                  </p>
                  <p className="testiname">Kay Wawro</p>
                  <p className="testicountry">Thailand</p>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
        <Footer />

        <Modal
          isOpen={consultmodalIsOpen}
          onRequestClose={closeModal}
          id="registermodal"
        >
          <div className="inpersonmodal">
            <div className="modalheader">
              <p className="modaltitle">Get Consultation</p>
              <div
                className="modalclosediv"
                onClick={() => {
                  closeModal();
                }}
              >
                <FontAwesomeIcon icon={faXmark} className="coursecloseicons" />
              </div>
            </div>
            <div className="modalbody">
              <div className="registermodalmaindiv">
                <div className="learner-inputdiv">
                  <p className="ordersummary-course-schedulerowkey">
                    Full Name <span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    value={fullname}
                    onChange={(t) => {
                      setfullname(t.target.value);
                    }}
                    type="text"
                    className="form-control order-summaryinput"
                    placeholder="Enter Full Name"
                  />
                </div>
                <div className="learner-inputdiv">
                  <p className="ordersummary-course-schedulerowkey">
                    Email Address <span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    value={email}
                    onChange={(t) => {
                      setemail(t.target.value);
                    }}
                    type="email"
                    className="form-control order-summaryinput"
                    placeholder="Enter Email Address"
                  />
                </div>
                <div className="learner-inputdiv">
                  <p className="ordersummary-course-schedulerowkey">
                    Mobile Number <span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    value={number}
                    onChange={(t) => {
                      setnumber(t.target.value);
                    }}
                    type="number"
                    className="form-control order-summaryinput"
                    placeholder="Enter Contact Number"
                  />
                </div>
                <div className="learner-inputdiv">
                  <p className="ordersummary-course-schedulerowkey">Message (optional)</p>
                  <textarea
                    style={{ height: 80 }}
                    rows={5}
                    value={message}
                    onChange={(t) => {
                      setmessage(t.target.value);
                    }}
                    type="text"
                    className="form-control order-summaryinput"
                    placeholder="Enter Message"
                  ></textarea>
                </div>
                <div className="registerbtn" onClick={
                  !showloading ?
                  handlesubmitconsultation : null
                } >
                  {
                    showloading ?
                    <Spinner color='#fff' size={15} className='spinnerCenter' /> : 
                    <p>Submit</p>
                  }
                  
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/*In Person modals */}

        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal1}
            contentLabel="Example Modal"
            id="inpersommodalid"
          >
            <div className="inpersonmodal">
              <div className="modalheader">
                <p className="modaltitle">
                  Batches For {selectedcoursenameRef.current}
                </p>
                <div
                  className="modalclosediv"
                  onClick={() => {
                    closeModal1();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="coursecloseicons"
                  />
                </div>
              </div>
              <div className="schedulerow">
                <div className="schedulecheckboxdiv">
                  <input type="checkbox" 
                  onChange={(t) => {handlechangecheckbox(t, 0)}}
                  checked={schedulecheck == 0 ? true : false}
                   />
                  <p className="schedule-inpersontitle">In-Person Classroom Training</p>
                </div>
                <div className="schedulecheckboxdiv">
                  <input type="checkbox"  
                  onChange={(t) => {handlechangecheckbox(t, 1)}}
                  checked={schedulecheck == 1 ? true : false}
                  />
                  <p className="schedule-inpersontitle">Live Online Training</p>
                </div>
              </div>
              <div className="modalbody">
                <div className="modalbatchfiltersection">
                  <div className="row">
                    <div className="col-md-3">
                      <p for="sel1" className="form-label modalformlabel">
                        Select Country
                      </p>
                      <select
                        className="form-select"
                        id="sel1"
                        name="sellist1"
                        value={selectedcountry}
                        onChange={(t) => {
                          setselectedcountry(t.target.value);
                        }}
                      >
                        <option disabled selected>
                          Select Country
                        </option>
                        {countriesdropdown?.length > 0
                          ? countriesdropdown?.map((item, index) => {
                              return (
                                <option value={item?.country}>
                                  {item?.country}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                    {
                      schedulecheck == 0 ?
                      <div className="col-md-3">
                      <p for="sel1" className="form-label modalformlabel">
                        Select City
                      </p>
                      <select
                        className="form-select"
                        id="sel1"
                        name="sellist1"
                        value={selectedcity}
                        onChange={(t) => {
                          setselectedcity(t.target.value);
                        }}
                      >
                        <option disabled selected>
                          Select City
                        </option>
                        {citiesdropdown?.length > 0
                          ? citiesdropdown?.map((item, index) => {
                              return (
                                <option value={item?.city}>{item?.city}</option>
                              );
                            })
                          : null}
                      </select>
                    </div> : null
                    }
                    
                    <div className="col-md-3">
                      <p for="sel1" className="form-label modalformlabel">
                        Select Month
                      </p>
                      <select
                        className="form-select"
                        id="sel1"
                        name="sellist1"
                        value={selectedmonth}
                        onChange={(t) => {
                          setselectedmonth(t.target.value);
                        }}
                      >
                        <option disabled selected>
                          Select Month
                        </option>
                        {monthsdropdown?.length > 0
                          ? monthsdropdown?.map((item, index) => {
                              return (
                                <option value={item.date}>{item.month}</option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <label for="course">&nbsp;</label>
                      {InpersonSearchbtn()}
                    </div>
                  </div>
                  <div className="resultsdiv">
                    <p className="batchesavailablehead">Batches Available</p>
                    { schedulecheck == 0 ?
                    inpersonbatchesdata?.length > 0 ? (
                      inpersonbatchesdata?.map((item, index) => {
                        // console.log('item >>', item);
                        return (
                          <div className="inpersondisplayrow">
                            <div className="row">
                              <div className="col-12 col-md-4">
                                <div className="popupdate">
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    className="popupicons"
                                  />
                                  <p className="popupdatetext">
                                    {item?.startdate} - {item?.enddate}
                                  </p>
                                </div>
                                <div className="popupdate popupcountry">
                                  <p className="popupdatetext popupcountrytext">
                                    Country :
                                  </p>
                                  <p className="popupdatetext popupdatetext1">
                                    {item?.country}
                                  </p>
                                </div>
                              </div>
                              <div className="col-12 col-md-3">
                                <div className="popupdate">
                                  <FontAwesomeIcon
                                    icon={faClock}
                                    className="popupicons"
                                  />
                                  <p className="popupdatetext">
                                    {item?.starttime} - {item?.endtime}
                                  </p>
                                </div>
                                <div className="popupdate popupcountry">
                                  <p className="popupdatetext popupcountrytext">
                                    City :
                                  </p>
                                  <p className="popupdatetext popupdatetext1">
                                    {item?.city}
                                  </p>
                                </div>
                              </div>
                              <div className="col-12 col-md-3">
                                <div className="popupdate">
                                  <FontAwesomeIcon
                                    icon={faDollar}
                                    className="popupicons"
                                  />
                                  <p
                                    className="popupdatetext"
                                    style={{ marginLeft: 5 }}
                                  >
                                    USD <del>{item?.price}</del>
                                    <span className="popupofferprice">
                                      {item?.offerprice}
                                    </span>
                                  </p>
                                </div>
                                <div className="popupdate popupcountry">
                                  <p className="popupdatetext popupcountrytext">
                                    {item?.weektype}
                                  </p>
                                </div>
                              </div>
                              <div
                                className="col-12 col-md-2"
                                onClick={() => {
                                  handleinpersonclassroomclick(item);
                                }}
                              >
                                <button
                                  href="cart"
                                  className="form-control btn btn-success popupenrollbtn"
                                >
                                  Enroll
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : shownobatchesavailable ? (
                      <p className="nobatchestext">
                        No batches available for selected month.
                      </p>
                    ) : null 
                  :
                  liveonlinebatchesdata?.length > 0 ? (
                    liveonlinebatchesdata?.map((item, index) => {
                      // console.log('item >>', item);
                      return (
                        <div className="inpersondisplayrow">
                          <div className="row">
                            <div className="col-12 col-md-4">
                              <div className="popupdate">
                                <FontAwesomeIcon
                                  icon={faCalendar}
                                  className="popupicons"
                                />
                                <p className="popupdatetext">
                                  {item?.startdate} - {item?.enddate}
                                </p>
                              </div>
                              <div className="popupdate popupcountry">
                                <p className="popupdatetext popupcountrytext">
                                  Country :
                                </p>
                                <p className="popupdatetext popupdatetext1">
                                  {item?.country}
                                </p>
                              </div>
                            </div>
                            <div className="col-12 col-md-3">
                              <div className="popupdate">
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className="popupicons"
                                />
                                <p className="popupdatetext">
                                  {item?.starttime} - {item?.endtime}
                                </p>
                              </div>
                              {/* <div className="popupdate popupcountry">
                          <p className="popupdatetext popupcountrytext">
                          City :
                          </p>
                          <p className="popupdatetext popupdatetext1">
                          {item?.city}
                          </p>
                        </div> */}
                            </div>
                            <div className="col-12 col-md-3">
                              <div className="popupdate">
                                <FontAwesomeIcon
                                  icon={faDollar}
                                  className="popupicons"
                                />
                                <p
                                  className="popupdatetext"
                                  style={{ marginLeft: 5 }}
                                >
                                  USD <del>{item?.price}</del>
                                  <span className="popupofferprice">
                                    {item?.offerprice}
                                  </span>
                                </p>
                              </div>
                              <div className="popupdate popupcountry">
                                <p className="popupdatetext popupcountrytext">
                                  {item?.weektype}
                                </p>
                              </div>
                            </div>
                            <div
                              className="col-12 col-md-2"
                              onClick={() => {
                                handleliveonlineclassroom(item);
                              }}
                            >
                              <button
                                href="cart"
                                className="form-control btn btn-success popupenrollbtn"
                              >
                                Enroll
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : shownobatchesavailable ? (
                    <p className="nobatchestext">
                      No batches available for selected month.
                    </p>
                  ) : null
                  }
                  </div>
                </div>
              </div>
            </div>
          </Modal>
      </>
    );
}

export default Home