import React from "react";
import { Route, Link, BrowserRouter as Router, Routes } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faUserCheck,
  faCalendar,
  faStar,
  faDesktop,
} from "@fortawesome/free-solid-svg-icons";

export const CourseCard = ({data, selectedcourse}) => {
  // console.log('data >>', data)
  var maxlength = 130;
  var description = data?.shortdesc?.length > 0 ? data?.shortdesc.slice(0, maxlength) + "..." : data?.shortdesc;
  var number = Math.floor(1000 + Math.random() * 9000);

  const handleviewcourse = (t) => {
    selectedcourse(t);
  }
  return (
    <div className="course-card-body">
      <p className="course-card-title">
        {data?.title}
      </p>
      <div className="desc-maindiv">
        <div className="icondiv">
          <FontAwesomeIcon icon={faCircleCheck} className="iconstyles" />
        </div>
        <p className="course-card-desc">
        {description}
        </p>
      </div>
      <div className="features-div">
        <div className="row">
          <div className="col-md-6">
            <div className="features1-div">
              <FontAwesomeIcon icon={faUserCheck} className="iconstyles" />
              <p className="userchecktext">{data?.enroll ? data?.enroll : number}+ Enrolled<br /> Already</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="features1-div">
              <FontAwesomeIcon icon={faCalendar} className="iconstyles" />
              <p className="userchecktext">Weekdays / Weekends</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="features1-div">
              <FontAwesomeIcon icon={faStar} className="iconstyles" />
              <p className="userchecktext">{data?.googlerating} / 5</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="features1-div">
              <FontAwesomeIcon icon={faDesktop} className="iconstyles" />
              <p className="userchecktext">Live Online Class</p>
            </div>
          </div>
        </div>
      </div>
      <hr className="course-card-hr" />
      <div className="banner-btn-div coursecard-banner-btn-div">
        <Link to={"/courses/"+data?.category+"/"+data?.slug} className="course-btn">
          <p>View Course</p>
        </Link>
        <div className="course-btn course-btn1" style={{cursor: 'pointer'}} onClick={() => {handleviewcourse(data?.slug)}}>
          <p>View Schedule</p>
        </div>
      </div>
    </div>
  );
};
