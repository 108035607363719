import React, { useState } from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlagUsa,
  faPhone,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { faCanadianMapleLeaf } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Spinner from "react-activity/dist/Spinner";
import { apiUrl } from "../config/Config";
import axios from "axios";

const RefundPolicy = () => {
  return (
    <>
      <Header />
      <div className="breadscrumbg">
        <div className="pagewidth">
          <p className="breadscrumb-pagetitle">Refund Policy</p>
          <p className="breadscrumb-text"></p>
        </div>
      </div>
      <div className="home-section2 contact-section1">
        <div className="pagewidth">
          <div className="privacycontent">
            
            <p className="privacyhead">Rescheduling Policy</p>
            <p className="choose-description">If the training session is rescheduled by Upskills Certifications, we will provide you with alternate class dates, If the rescheduled dates are not suitable you are eligible for a 90% refund of the payment, with a 10% deduction of administrative charges. Alternatively, the participant has the privilege to reschedule the class date and attend any of our future classes of their convenience, at the same location for the In- person class room training or if they have registered for Virtual online class then they can choose the class date of their convenience and attend the online class. Additionally, you have the option to send a replacement attendee if you are unable to attend.</p>
            <p className="choose-description">However, it is important to note that rescheduling requests are subject to certain conditions and availability. If a participant wish to reschedule their registration to a future date due to unforeseen reasons of their own, a rescheduling fee will be charged based on the following criteria:</p>
            <ul>
                <li className="choose-description">No charge for rescheduling requests received 15 working days or more in advance. </li>
                <li className="choose-description">A 10% registration fee will be charged for rescheduling requests received within 15 working days. Please note that rescheduling is subject to seat availability.</li>
                <li className="choose-description">Rescheduling requests within 7 working days or less of the scheduled date will not be permitted. However, the participant may choose to substitute an attendee for the same date (subject to availability) or opt for a different company-assigned session, provided they have informed the company at least 7 days’ prior the scheduled training.</li>
            </ul>
            <p className="choose-description">Please note that once the participant has downloaded the training course materials, no refunds will be granted for cancellations done by the participants for their own reasons.</p>

            <p className="privacyhead">Refund, Cancellation & Rescheduling Policy</p>
            <p className="choose-description">Like any online purchase, it is important to familiarize yourself with the terms and conditions that govern our Refund Policy. By purchasing a training course through our website, you are agreeing to abide by our Privacy Policy, Terms of Use, and the following guidelines:</p>
            <p className="choose-description"><b>To determine if you are eligible for a refund, please review the terms outlined below</b></p>
            <ol>
                <li className="choose-description"><b>Course Material Access:</b> Upon purchasing a training course, you will gain access to the course materials and resources associated with that particular course. It is important to note that once you have accessed or downloaded the course materials, your eligibility for a refund may be affected.</li>
                <li className="choose-description"><b>Refund Requests:</b> To request a refund, you must follow the procedure outlined in our Refund Policy. This typically involves reaching out to our support team and providing relevant information, such as the reason for your refund request and any supporting documentation if required.</li>
                <li className="choose-description"><b>Refund Processing:</b> Once your refund request is received and reviewed, our team will assess your eligibility based on the Refund Policy guidelines. If approved, the refund will be processed in accordance with our refund timelines and the original payment method used during the purchase.</li>
                <li className="choose-description"><b>Non-Refundable Fees:</b> Certain fees associated with your purchase, such as administrative charges or test/membership fees, may be non-refundable. Please refer to our Refund Policy for details on specific non-refundable fees.</li>
                <li className="choose-description">Refund Policy, Privacy Policy, and Terms of Use. It is essential to review these policies prior to making a purchase.</li>
            </ol>

            <p className="privacyhead">Cancellation & Refunds</p>
            <p className="choose-description"><b>Classroom / Live Virtual Classroom Training</b></p>
            <p className="choose-description">Upskills understands that unforeseen circumstances may arise that require us to postpone, cancel, or change the location of an event. Such circumstances may include insufficient enrolments, technical issues, instructor illness, or force-majeure events like floods, earthquakes, or political instability.</p>
            <ul>
                <li className="choose-description">If Upskills cancels a workshop, we will provide a 90% refund to the delegates, with the remaining 10% being charged as an administrative fee. However, if a participant cancels their participation in an event 15 days or more prior to the scheduled date, a deduction of 15% of the total paid fee will be made, and the remaining amount will be refunded. On the other hand, if a cancellation is made within 7 days or less before the event, no refunds will be granted.</li>
                <li className="choose-description">Participants requesting a refund within this timeframe (15 days or more prior to the scheduled date) may be required to provide a valid reason.</li>
                <li className="choose-description">It is important to note that non-attendance or cancellation of a class more than 6 months after the initial registration will result in forfeiture of the tuition paid. To initiate the refund process or address any queries related to cancellations, participants can reach out to us via email.</li>
                <li className="choose-description">If a participant chooses to cancel their participation for what so ever reasons best known to them within 7 working days from the date of their payment, either if it is a In person classroom training or for the Instructor led live virtual class we will not process any refund instead a substitute can be provided for any of their suitable future class date. However, It’s important to note that once the participant has received any training course materials, no refunds will be granted for cancellations.</li>
            </ul>
            <p className="choose-description"><b>Online Self-Learning Training</b></p>
            <p className="choose-description">If a participant chooses to cancel their subscription within 24 hours of subscribing and has not consumed or downloaded any of the recorded sessions or training course materials, an administration fee of 15% of the total paid fee will be deducted. However, if the cancellation is made by the delegate after 24 hours of subscribing, no refund will be provided. It’s important to note that once the participant has downloaded any training course materials, no refunds will be granted for cancellations.</p>
            <p className="choose-description">Refunds: Duplicate Payment<b></b></p>
            <p className="choose-description">It’s important to note that all refund requests will be processed within 30 working days of receiving</p>
            <p className="choose-description">the request. Refunds will be issued either through the same payment mode used initially or via wire transfer.</p>
            <p className="choose-description"><b>Upskills certification reserves the right to revise this policies without any prior notice.</b></p>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RefundPolicy;
