import React from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import img1 from "../assets/about/1.png";
import { Imprints } from "../components/Imprints";
import img2 from "../assets/about/2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserTie,
  faCommentDollar,
  faChalkboardUser,
  faAward,
} from "@fortawesome/free-solid-svg-icons";

const Aboutus = () => {
  return (
    <>
      <Header />
      <div className="breadscrumbg">
        <div className="pagewidth">
          <p className="breadscrumb-pagetitle">About Us</p>
          <p className="breadscrumb-text">
            Committed to Professional's Excellence
          </p>
        </div>
      </div>
      <div className="home-section2 about-section1">
        <div className="pagewidth">
          <div className="row">
            <div className="col-md-8">
              <p className="section-head about-who-we-are">Who we are</p>
              <p className="choose-description about-us-description">
                At Upskills Certifications, we are passionate about driving
                professional growth and empowering individuals and organizations
                to thrive in today’s rapidly evolving business landscape. As a
                leading provider of comprehensive corporate training solutions,
                we offer a wide range of certification courses designed to
                enhance the skills and expertise of working professionals across
                various industries.
              </p>
            </div>
            <div className="col-md-4">
              <img src={img1} className="about-us-img" />
            </div>
          </div>
        </div>
      </div>
      <div className="home-section2 about-section2">
        <div className="pagewidth">
          <div className="row">
            <div className="col-md-6">
              <div className="about-left-div">
                <p className="section-head">Vision</p>
                <p className="vision-subtext">
                  Driving Transformation: Our Vision for Personal and
                  Professional Growth
                </p>
                <p className="choose-description vision-desc">
                  Our vision at Upskills Certifications is to be the catalyst
                  for personal and professional transformation. We aim to become
                  the go-to platform for individuals seeking to excel in their
                  careers and organizations striving for excellence. By offering
                  innovative and industry-relevant training programs, we
                  envision empowering individuals with the skills and
                  capabilities needed to adapt, grow, and succeed in an
                  ever-evolving business landscape.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-left-div about-right-div">
                <p className="section-head mission-head">Mission</p>
                <p className="vision-subtext mission-subtext">
                  Bridging the Skills Gap: Our Mission to Empower Individuals &
                  Organizations
                </p>
                <p className="choose-description vision-desc">
                  Our mission is to bridge the gap between current skills and
                  future requirements by offering high-quality corporate
                  training programs. We are committed to providing training
                  methodologies that leverage the latest technologies,
                  research-backed approaches, and practical insights. By
                  creating a dynamic learning environment, we enable individuals
                  and organizations to unlock their full potential, foster
                  continuous growth, and embrace innovation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Imprints />
      <div className="home-section2 about-section3">
        <div className="pagewidth">
          <div className="row">
            <div className="col-md-7">
              <p className="section-head about-who-we-are">
                Why Upskills Certifications ?
              </p>
              <div className="about-why-subdiv">
                <FontAwesomeIcon icon={faUserTie} className="abouticons" />
                <p className="about-why-subhead">
                  Expert & Learners Friendly Instructors
                </p>
              </div>
              <p className="choose-description vision-desc">
                Learn from industry professionals and subject matter experts who
                bring extensive experience and insights to the training
                programs, providing valuable perspectives and guidance.
              </p>
              <div className="about-why-subdiv">
                <FontAwesomeIcon
                  icon={faCommentDollar}
                  className="abouticons"
                />
                <p className="about-why-subhead">Cost Effective Trainings</p>
              </div>
              <p className="choose-description vision-desc">
                We offer high-quality standards at competitive prices, ensuring
                accessibility and affordability for learners.
              </p>
              <div className="about-why-subdiv">
                <FontAwesomeIcon
                  icon={faChalkboardUser}
                  className="abouticons"
                />
                <p className="about-why-subhead">Practical Learning Approach</p>
              </div>
              <p className="choose-description vision-desc">
                Our certifications focus on practical, hands-on learning
                experiences, enabling participants to apply their knowledge in
                real-world scenarios and projects.
              </p>
              <div className="about-why-subdiv">
                <FontAwesomeIcon icon={faAward} className="abouticons" />
                <p className="about-why-subhead">Recognition and Credibility</p>
              </div>
              <p className="choose-description vision-desc">
                Earn certifications that are widely recognized and respected in
                the industry, enhancing your professional credibility and
                opening doors to new opportunities.
              </p>
            </div>
            <div className="col-md-5">
              <img src={img2} className="about-img2" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Aboutus;
