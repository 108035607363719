import React, { useState } from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlagUsa, faPhone, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faCanadianMapleLeaf} from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import Spinner from "react-activity/dist/Spinner";
import { apiUrl } from "../config/Config";
import axios from 'axios';

const PrivacyPolicy = () => {
    

    return (
      <>
        <Header />
        <div className="breadscrumbg">
          <div className="pagewidth">
            <p className="breadscrumb-pagetitle">Privacy Policy</p>
            <p className="breadscrumb-text">
              
            </p>
          </div>
        </div>
        <div className="home-section2 contact-section1">
            <div className="pagewidth">
                <div className="privacycontent">
                    <p className="privacyhead">Overview</p>
                    <p className="choose-description">We take your privacy seriously and are committed to protecting your right to privacy as a user of our website. We have made every effort to ensure your information is secure. This privacy policy information covers what information is collected, what we do with it, and what you can do about it. You can use this information to make your decisions about your privacy.</p>

                    <p className="privacyhead">About us and the website</p>
                    <p className="choose-description">Upskillscert.com provides instructor-led and classroom training for PMP ® , CAPM ® , Six Sigma, ITIL ® , Primavera, MS ®  SharePoint, PMI-ACP ® , PMI-RMP ® , Minitab ® , MS ®  Project, PRINCE2, Salesforce, Big data hadoop, SAP. Our courses are designed to help learners in professional certification exam preparation.</p>
                
                    <p className="privacyhead">Upskills Certifications Information Practices</p>
                    <p className="choose-description">Every effort has been taken to ensure the accuracy of the information on the website the content is naturally subject to change. We are not responsible for any damages arising from the use of the information held on this server.</p>
                    <p className="choose-description">We assure that all our downloadable training content is virus free. However, Upskills Certifications can accept no liability for damages resulting from virus infection.</p>

                    <p className="privacyhead">Information collected by Upskills Certifications</p>
                    <ul>
                        <li className="choose-description">You have total control on the privacy of your information.</li>
                        <li className="choose-description">Your information will never be sold, exchanged or disclosed to any third party for marketing purposes.</li>
                        <li className="choose-description">You can opt to receive offers from us.</li>
                    </ul>

                    <p className="privacyhead">You can browse our website without registration</p>
                    <p className="choose-description">We may collect and store the information that you voluntarily disclose to us, in order to access our free tests and discussion forum. We do not share your personal information with any third party. We do not collect information about our visitors from email databases, private/ public organization or bodies.</p>
                    <p className="choose-description">We may use your information to contact you via email or telephone, typically to get feedback, to inform you about new products or provide support on your issues. If you do not want us to contact you via email, you can unsubscribe at any time.</p>
                    <p className="choose-description">When you purchase our online products or register for a classroom training, you need to make the payment online, thus you will be required to share with us your personal information such as name, e-mail address, telephone number, address(s), credit card number, expiration, and CVV number.</p>
                    <p className="choose-description">During this program, we store your contact information in our registration and order-entry systems. Your credit card information is never stored in our system as it is processed by our payment gateway which uses Secure Encryption Technology (SSL).</p>
                    <p className="choose-description">Our payment processing partners are PayPal and PayUmoney.</p>

                    <p className="privacyhead">Cookie Information</p>
                    <p className="choose-description">In order to enhance your online experience and track website performance, our website uses cookies. Its a small text file which gets placed in your computer hard drive and can be retrieved later. Cookies do not tell us who you are.</p>

                    <p className="privacyhead">E-commerce tracking</p>
                    <p className="choose-description">To provide superior online shopping experience, our shopping cart may use cookies to temporarily store names and email addresses. Cookies will never store credit card information as they are processed using payment gateways that use Secure Encryption Technology.</p>

                    <p className="privacyhead">External Links</p>
                    <p className="choose-description">Our website may have links to other sites, which include third party websites or resources. We are not responsible for the privacy policies and information practices of third party websites. Please read the privacy policies of third party websites as every website has different terms of use and privacy policies.</p>

                    <p className="privacyhead">Course Content</p>
                    <p className="choose-description">Course content for all the study programs both online and blended programs, is provided only for the purpose of education and guidance. The course content is reviewed regularly and is subject to change without notice. Upskills Certifications and it’s partners reserves the right to modify training content without notice.</p>

                    <p className="privacyhead">Copyright information</p>
                    <p className="choose-description">You can electronically copy or take print outs of the website pages, only for personal use. Upskills Certifications and it’s partners holds the copyright to all the material on this website, unless otherwise indicated. A written permission from the copyright holder must be obtained for any use of this material other than for purposes permitted by law.</p>

                    <p className="privacyhead">Personal Information Policy Image and Video Capture</p>
                    <p className="choose-description">Any images captured during events organized and hosted by Upskills Certifications should not be misused. That includes pictures of Upskills Certifications staff and training participants. If you enroll in a Upskills Certifications course your picture may also be featured in photos or videos of the Upskills Certifications classes.</p>

                    <p className="privacyhead">Sharing your personal information</p>
                    <p className="choose-description">If you have purchased a Upskills Certifications course, we may ask you for your feedback, and display your testimony, your public information, photos or videos on our website or on our social media channels such as Facebook, YouTube or Flickr. You should be aware that your publicly identifiable or displayed information could be used by third parties to send you unsolicited promotional, unsolicited messages. We are not responsible for your personal information which you have chosen to display and any unsolicited promotional messages that you may receive from third parties.</p>
                    <p className="choose-description">If you do not want us to feature your pictures/testimonials/public information on our website or on our social media channels, please reach out to our support team through our Help &amp; Support section on the website.</p>

                    <p className="privacyhead">Personal Information Corrections</p>
                    <p className="choose-description">You can contact us if you notice that the information we are holding is incorrect or incomplete. Please reach out to our support team through our Help &amp; Support section on the website.</p>

                    <p className="privacyhead">Changes to Privacy Policy</p>
                    <p className="choose-description">These privacy policy terms may change time to time and we reserve the right to change the terms of the privacy policy at any time. When we update the privacy policy, all our registered users will be notified via email.</p>

                    <p className="privacyhead">Deleting Personal Information</p>
                    <p className="choose-description">If you would like to delete your account, please reach out to our support team through our Help &amp; Support section on the website.</p>

                    <p className="privacyhead">Privacy Policy Questions?</p>
                    <p className="choose-description">If you have any questions regarding Upskills Certifications Privacy Policy, please reach out to our support team through our Help &amp; Support section on the website.</p>

                    
                </div>
                
            </div>
        </div>
        <Footer />
      </>
    );
} 

export default PrivacyPolicy