import React, { useEffect } from 'react';

const PayPalButton = ({ amount, onSuccess, height, onCancel }) => {
  useEffect(() => {
    // Load the PayPal script
    const script = document.createElement('script');
    script.src = 'https://www.paypal.com/sdk/js?client-id=AT_-us7w-v1TY4KtnesDgyhOddjtwvy3Wz_6iBzwOAeKO-DXR1-Zm_Y7ATLwtALpV1uwfUUxVZJYleT4&components=buttons&currency=USD';
    script.addEventListener('load', () => {
      // Render the PayPal button
      window.paypal.Buttons({
        style: {
            height: height, 
          },
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: amount,
              },
            }],
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then(details => {
            onSuccess(details);
          });
        },
        onCancel: (data) => {
            onCancel(data);
          },
        onError: (err) => {
          // console.error('err >>', err);
        }
      }).render('#paypal-button-container');
    });

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [amount, onSuccess]);

  return <div id="paypal-button-container"></div>;
};

export default PayPalButton;
