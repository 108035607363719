import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../../components/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { Link, json, useNavigate } from "react-router-dom";
import Editor from "../../components/Editor";
import axios from "axios";
import { apiUrl } from '../../config/Config';
import { useParams } from 'react-router-dom';


const EditCourse = () => {
    let navigate = useNavigate();
    const { id } = useParams();
  const userdata = useSelector((store) => store.userReducer.userdata);
  const [coursedetails, setcoursedetails] = useState({});
  const [categorydata, setcategorydata] = useState([]);
  const [selectedcategory, setselectedcategory] = useState(null);
  const [title, settitle] = useState(null);
  const [enroll, setenroll] = useState(null);
  const [description, setdescription] = useState("");
  const [googlereview, setgooglereview] = useState("");
  const [trustpilotreview, settrustpilotreview] = useState("");
  const [courseoverview, setcourseoverview] = useState("");
  const [aboutcourse, setaboutcourse] = useState("");
  const [keyfeatures, setkeyfeatures] = useState("");
  const [whocanattend, setwhocanattend] = useState("");
  const [uploadbrochure, setuploadbrochure] = useState("");
  const [currentbrochure, setcurrentbrochure] = useState({});
  const [schedulessubtext, setschedulessubtext] = useState("");
  const [inpersonpoints, setinpersonpoints] = useState("");
  const [inpersonprice, setinpersonprice] = useState("");
  const [inpersonofferprice, setinpersonofferprice] = useState("");
  const [liveonlinepoints, setliveonlinepoints] = useState("");
  const [liveonlineprice, setliveonlineprice] = useState("");
  const [liveonlineofferprice, setliveonlineofferprice] = useState("");
  const [onlineselfpoints, setonlineselfpoints] = useState("");
  const [onlineselfprice, setonlineselfprice] = useState("");
  const [onlineselfofferprice, setonlineselfofferprice] = useState("");
  const [editorLoaded, setEditorLoaded] = useState(true);
  const [courseagenda, setcourseagenda] = useState([{title: "", description: ""}])
  const [coursefaq, setcoursefaq] = useState([{title: "", description: ""}])
  const [reviews, setreviews] = useState([{name: "",rating: "", country: "", description: ""}])
  const [forceupdate, setforupdate] = useState(false);
  const [sdesc, setsdesc] = useState(null);
  const courseagendaRef = useRef([]);
  courseagendaRef.current = courseagenda;
  const coursefaqRef = useRef([]);
  coursefaqRef.current = coursefaq;
  const reviewsRef = useRef([]);
  reviewsRef.current = reviews;
  const [data, setData] = useState("");
  const [trainers, settrainers] = useState([{name: '',degree: '', description: '', image: ''}]);
  const trainersRef = useRef([]);
  trainersRef.current = trainers;


//   const handleEditorChange = (event, editor) => {
//     console.log(editor.getData());
//   };

  const Displaytext = () => {
    const parser = new DOMParser();
    var datas = data;
    const htmlString = data;

    // for p tags
    const cleanedHtmlString = datas.replace(/<ul>[\s\S]*?<\/ul>/g, '');

    // for li tags
    const doc = parser.parseFromString(datas, 'text/html');
    const liElements = doc.querySelectorAll('li');
    const liTextContent = Array.from(liElements).map(li => li.textContent);


    // setLiContent(liTextContent);
    return (
        
        // <div dangerouslySetInnerHTML={{ __html: htmlString }} />
        <div className="">
            <div dangerouslySetInnerHTML={{ __html: cleanedHtmlString }} />
            {
                liTextContent?.length > 0 ?
                liTextContent?.map((item, index) => {
                    return (
                        <p>{item}</p>
                    )
                }) : null
            }
        </div>
    )
  }

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        // console.log("Called", reader);
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  const uploaddocument = async(file) => {
    await getBase64(file).then(result => {
      // console.log('result ...', result);
      // base64_decode($base64encodedstring)
      var obj = {};
      obj['name'] = file.name;
      obj['image'] = result;
      console.log('obj >>>', obj);
      setuploadbrochure(obj);
      // data[index][name] = obj;
    });
  }

  const uploadtrainerimg = async(index,name, file) => {
    
    await getBase64(file).then(result => {
      var olddata = trainersRef?.current;
       olddata[index][name] = result;
      settrainers(olddata);
    });
    setforupdate(!forceupdate);
  }


  const appendcourseagenda = async() => {
    var newobj = {title: '', description: ''};
    var olddata = courseagendaRef.current;
    olddata.push(newobj);
    setcourseagenda(olddata);
    setforupdate(!forceupdate);
  }

  const removecourseagenda = async() => {
    var olddata = courseagendaRef.current;
    olddata.pop();
    setcourseagenda(olddata);
    setforupdate(!forceupdate);
  }

  const appendcoursefaq = async() => {
    var newobj = {title: '', description: ''};
    var olddata = coursefaqRef.current;
    olddata.push(newobj);
    setcoursefaq(olddata);
    setforupdate(!forceupdate);
  }

  const removecoursefaq = async() => {
    var olddata = coursefaqRef.current;
    olddata.pop();
    setcoursefaq(olddata);
    setforupdate(!forceupdate);
  }

  const appendreviews = async() => {
    var newobj = {name: '',rating: '', country: '', description: ''};
    var olddata = reviewsRef.current;
    olddata.push(newobj);
    setreviews(olddata);
    setforupdate(!forceupdate);
  }

  const removereviews = async() => {
    var olddata = reviewsRef.current;
    olddata.pop();
    setreviews(olddata);
    setforupdate(!forceupdate);
  }

  const changeagendatext = async(index, name, value) => {
    var olddata = courseagendaRef?.current;
    olddata[index][name] = value;
    setcourseagenda(olddata);
    setforupdate(!forceupdate);
  }

  const changefaqtext = async(index, name, value) => {
    var olddata = coursefaqRef?.current;
    olddata[index][name] = value;
    setcoursefaq(olddata);
    setforupdate(!forceupdate);
  }

  const changereviewtext = async(index, name, value) => {
    var olddata = reviewsRef?.current;
    olddata[index][name] = value;
    setreviews(olddata);
    setforupdate(!forceupdate);
  }

  const submitcourse = async() => {
    var data = JSON.stringify({
      id: id,
      title: title,
      category: selectedcategory,
      enroll: enroll,
      shortdesc: sdesc,
      headparagh: description,
      googlerating: googlereview,
      trustrating: trustpilotreview,
      courseoveriew: courseoverview,
      coursedescription: aboutcourse,
      keyfeatures: keyfeatures,
      attenddescription: whocanattend,
      uploadbrochure: uploadbrochure,
      coursemodules: courseagendaRef?.current,
      scheduledescription: schedulessubtext,
      inpersonpoints: inpersonpoints,
      personprice: inpersonprice,
      personofferprice: inpersonofferprice,
      liveonlinepoints: liveonlinepoints,
      liveonlineprice: liveonlineprice,
      liveonlineofferprice: liveonlineofferprice,
      onlineselfpoints: onlineselfpoints,
      onlineselfprice: onlineselfprice,
      onlineselfofferprice: onlineselfofferprice,
      faqs: coursefaqRef?.current,
      reviews: reviewsRef?.current,
      trainers: trainersRef?.current
    });
    console.log('data >>', data);
    const headers = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + userdata?.token
      }
    }
    let resp = await axios.post(apiUrl + 'editcourse', data, headers).then((res) => {
      console.log('res >>', res)
      if(res.data.status == 'success') {
        navigate('/webadmin/courselist');
      } else {
        alert(res.data.message);
      }
  } ).catch((err) => {
      alert(err.message)
  })
  }

  const fetchproductsbyid = async() => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .get(apiUrl + "getallcategories", headers)
      .then((res) => {
        if (res.data.status == "success") {
          // console.log('getallcategories >>', res.data);
          setcategorydata(res.data.categorydata);
        } else {
          // alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }
  const fetchcoursebyid = async(id) => {
    var data = JSON.stringify({
        id: id
    })
    const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + userdata?.token
        }
      }
      let resp = await axios.post(apiUrl + 'getcoursedetails', data, headers).then((res) => {
        console.log('res >>', res.data)
        setcoursedetails(res.data.data);
        var prevdata = res.data.data
        settitle(prevdata.title);
        setenroll(prevdata?.enroll);
        setsdesc(prevdata?.shortdesc);
        setselectedcategory(prevdata.category);
        setdescription(prevdata.headparagh ? prevdata.headparagh : "");
        setgooglereview(prevdata.googlerating ? prevdata.googlerating : "");
        settrustpilotreview(prevdata.trustrating ? prevdata.trustrating : "");
        setcourseoverview(prevdata.courseoveriew ? prevdata.courseoveriew : "");
        setaboutcourse(prevdata.coursedescription ? prevdata.coursedescription : "");
        setkeyfeatures(prevdata.keyfeatures ? prevdata.keyfeatures : "");
        setwhocanattend(prevdata.attenddescription ? prevdata.attenddescription : "");
        // setuploadbrochure(prevdata.uploadbrochure);
        if(prevdata.uploadbrochure) {
          setcurrentbrochure(JSON.parse(prevdata.uploadbrochure));
        }

        if(prevdata.coursemodules) {
          
          var parseprevdata = JSON.parse(prevdata.coursemodules);
          if(parseprevdata[0]?.title && parseprevdata[0]?.description) {
            setcourseagenda(JSON.parse(prevdata.coursemodules));
          }
        }
        
        
        setschedulessubtext(prevdata.scheduledescription ? prevdata.scheduledescription : "");
        setinpersonpoints(prevdata.inpersonpoints ? prevdata.inpersonpoints : "");
        setliveonlinepoints(prevdata.liveonlinepoints ? prevdata.liveonlinepoints : "");
        setonlineselfpoints(prevdata.onlineselfpoints ? prevdata.onlineselfpoints : "");
        setinpersonprice(prevdata.personprice ? prevdata.personprice : "");
        setinpersonofferprice(prevdata.personofferprice ? prevdata.personofferprice : "");
        setliveonlineprice(prevdata.liveonlineprice ? prevdata.liveonlineprice : "");
        setliveonlineofferprice(prevdata.liveonlineofferprice ? prevdata.liveonlineofferprice : "");
        setonlineselfprice(prevdata.onlineselfprice ? prevdata.onlineselfprice : "");
        setonlineselfofferprice(prevdata.onlineselfofferprice ? prevdata.onlineselfofferprice : "");
        if(prevdata.faqs) {
          var parseprevdata = JSON.parse(prevdata.faqs);
          if(parseprevdata[0]?.title && parseprevdata[0]?.description) {
            setcoursefaq(JSON.parse(prevdata.faqs))
          }
          
        }

        if(prevdata.reviews) {
          setreviews(JSON.parse(prevdata.reviews));
        }

        if(prevdata.trainers) {
          settrainers(JSON.parse(prevdata.trainers));
        }
        
        
        // if(res.data.status == 'success') {
          
        // } else {
        //   alert(res.data.message);
        // }
    } ).catch((err) => {
        alert(err.message)
    })
  }

  const appendcoursetrainer = async() => {
    var newobj = {name: '',degree: '', description: ''};
    var olddata = trainersRef.current;
    olddata.push(newobj);
    settrainers(olddata);
    setforupdate(!forceupdate);
  }

  const removecoursetrainer = async () => {
    var olddata = trainersRef.current;
    olddata.pop();
    settrainers(olddata);
    setforupdate(!forceupdate);
  }

  const changetrainertext = async(index, name, value) => {
    var olddata = trainersRef?.current;
    olddata[index][name] = value;
    settrainers(olddata);
    setforupdate(!forceupdate);
  }



    useEffect(() => {
    //   console.log('userdata >>', userdata);
    //   console.log('id >>>', id);
        if (userdata && Object.keys(userdata).length > 0) {
        //   fetchdata();
        fetchproductsbyid();
        fetchcoursebyid(id);
        } else {
          navigate("/newadminlogin");
        }
      }, []);



      return (
        <div className="HomeMain">
          <div className="sidebar-div">
            <Sidebar name={"Admin"} />
          </div>
          <div className="main-layout">
            <div className="mainDiv">
              <p className="oneplaceHead">Edit Course</p>
              <div className="contentdiv">
                <div className="adminformdiv">
                  <div className="admin-input-div">
                    <label>
                      <b>Title</b>
                    </label>
                    <input
                    value={title}
                    onChange={(t) => {settitle(t.target.value)}}
                      type="text"
                      class="form-control admininput"
                      placeholder="Enter Course Title"
                      name="name"
                    />
                  </div>
                  <div className="admin-input-div mt-4">
                  <label>
                      <b>Category</b>
                    </label>
                  <select class="form-control" name="category" id="cars" value={selectedcategory} onChange={(t) => {setselectedcategory(t.target.value)}}>
                        <option selected disabled>Select Course Category</option>
                        {/* @foreach($category as $cat)
                        <option value="{{$cat->slug}}">{{$cat->categoryname}}</option>
                        @endforeach */}
                        {
                          categorydata?.length > 0 ?
                          categorydata?.map((item, index) => {
                            return (
                              <option key={index} value={item.slug}>{item.categoryname}</option>
                            )
                          }) : null
                        }
                    </select>
                  </div>

                  <div className="admin-input-div mt-3">
                    <label>
                      <b>Students Enrolled</b>
                    </label>
                    <input
                    value={enroll}
                    onChange={(t) => {setenroll(t.target.value)}}
                      type="number"
                      class="form-control admininput"
                      placeholder="Enter number of enrollment"
                      name="name"
                    />
                  </div>

                  <div className="admin-input-div mt-4">
                    <label>
                      <b>Short Description</b>
                    </label>
                    <textarea
                    value={sdesc}
                    onChange={(t) => {setsdesc(t.target.value)}}
                      type="text"
                      class="form-control admininput"
                      placeholder="Enter Course Short Description"
                      name="name"
                    ></textarea>
                  </div>
                  
                  <div className="admin-input-div mt-4">
                    <label>
                      <b>Description</b>
                    </label>
                    {/* <input
                      type="text"
                      class="form-control admininput"
                      placeholder="Enter Course Title"
                      name="name"
                    /> */}
                    <Editor
                      name="description"
                      value={description}
                      onChange={(t) => {
                        setdescription(t);
                      }}
                      editorLoaded={editorLoaded}
                    />
                    {/* <br /><br /> */}
                    {/* {Displaytext()} */}
                  </div>
                  <div className="admin-input-div mt-4">
                    <div className="row">
                      <div className="col-md-6">
                        <label>
                          <b>Google Review</b>
                        </label>
                        <input
                        value={googlereview}
                        onChange={(t) => {setgooglereview(t.target.value)}}
                          type="text"
                          class="form-control admininput"
                          placeholder="Enter Course Title"
                          name="name"
                        />
                      </div>
                      <div className="col-md-6">
                        <label><b>TrustPilot Review</b></label>
                        <input
                        value={trustpilotreview}
                        onChange={(t) => {settrustpilotreview(t.target.value)}}
                          type="text"
                          class="form-control admininput"
                          placeholder="Enter Course Title"
                          name="name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="admin-input-div mt-4">
                    <label>
                      <b>Course Overview</b>
                    </label>
                    <Editor
                      name="courseoveriew"
                      value={courseoverview}
                      onChange={(data) => {
                        setcourseoverview(data);
                      }}
                      editorLoaded={editorLoaded}
                    />
                  </div>
                  <div className="admin-input-div mt-4">
                    <label>
                      <b>About Course</b>
                    </label>
                    <Editor
                      name="courseoveriew"
                      value={aboutcourse}
                      onChange={(data) => {
                        setaboutcourse(data);
                      }}
                      editorLoaded={editorLoaded}
                    />
                  </div>
                  <div className="admin-input-div mt-4">
                    <label>
                      <b>Key Features</b>
                    </label>
                    <Editor
                      name="courseoveriew"
                      value={keyfeatures}
                      onChange={(data) => {
                        setkeyfeatures(data);
                      }}
                      editorLoaded={editorLoaded}
                    />
                  </div>
                  <div className="admin-input-div mt-4">
                    <label>
                      <b>Who Can Attend</b>
                    </label>
                    <Editor
                      name="courseoveriew"
                      value={whocanattend}
                      onChange={(data) => {
                        setwhocanattend(data);
                      }}
                      editorLoaded={editorLoaded}
                    />
                  </div>
                  <div className="admin-input-div mt-4">
                    <label>
                      <b>Upload Course Brochure</b>
                    </label>
                    <input
                      type="file"
                      accept="application/pdf"
                      // value={item['document']}
                      onChange={(t) => {
                        uploaddocument(t.target.files[0]);
                      }}
                      className="form-control tabinput"
                      placeholder="choose reference document"
                    />
                    <br />
                    {
                      Object.keys(currentbrochure) ?
                      <div className="">
                            <Link to={currentbrochure?.link} target="_blank">{currentbrochure?.name}</Link>
                      </div> : null
                    }
                  </div>
                  <div className="admin-input-div mt-4">
                    <label>
                      <b>Course Modules</b>
                    </label>
                    <br />
                    <div class="append-btns" style={{ float: "right" }}>
                      <span
                        class="btn btn-success plusbtn"
                        onClick={() => {
                          appendcourseagenda();
                        }}
                        id="addagendapoint"
                      >
                        +
                      </span>
                      {courseagendaRef.current?.length > 1 ? (
                        <span
                          class="btn btn-danger minusbtn"
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            removecourseagenda();
                          }}
                          id="removeagendapoint"
                        >
                          -
                        </span>
                      ) : null}
                    </div>
                    {courseagendaRef.current?.map((item, index) => {
                      return (
                        <div className="mt-3">
                          <label>{index + 1 + "."} Agenda Head</label>
                          <input
                          value={item.title}
                          onChange={(t) => {
                            changeagendatext(index, 'title', t.target.value);
                          }}
                            type=" text"
                            class="form-control agendaheadall"
                            name="agendahead[]"
                            placeholder="Agenda Head"
                          />
                          <label style={{ marginTop: 10 }}>
                            Agenda Description
                          </label>
                          <Editor
                          value={item.description}
                          onChange={(t) => {
                            changeagendatext(index, 'description', t);
                          }}
                            name="courseoveriew"
                            editorLoaded={editorLoaded}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="admin-input-div mt-4">
                    <label>
                      <b>Schedules Sub Heading</b>
                    </label>
                    <Editor
                      name="courseoveriew"
                      value={schedulessubtext}
                      onChange={(data) => {
                        setschedulessubtext(data);
                      }}
                      editorLoaded={editorLoaded}
                    />
                  </div>
                  <div className="admin-input-div mt-4">
                    <label>
                      <b>In Person Classroom Points</b>
                    </label>
                    <Editor
                      name="courseoveriew"
                      value={inpersonpoints}
                      onChange={(data) => {
                        setinpersonpoints(data);
                      }}
                      editorLoaded={editorLoaded}
                    />
                  </div>
                  <div className="admin-input-div mt-4">
                    <label>
                      <b>In Person Classroom Price</b>
                    </label>
                    <div className="row">
                      <div className="col-6">
                        <input
                        value={inpersonprice}
                        onChange={(t) => {
                          setinpersonprice(t.target.value);
                        }}
                          type="number"
                          class="form-control agendaheadall"
                          name="price"
                          placeholder="Price"
                        />
                      </div>
                      <div className="col-6">
                        <input
                        value={inpersonofferprice}
                        onChange={(t) => {
                          setinpersonofferprice(t.target.value);
                        }}
                          type="number"
                          class="form-control agendaheadall"
                          name="offerprice"
                          placeholder="Offer Price"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="admin-input-div mt-4">
                    <label>
                      <b>Live Online Points</b>
                    </label>
                    <Editor
                    value={liveonlinepoints}
                      name="courseoveriew"
                      onChange={(data) => {
                        setliveonlinepoints(data);
                      }}
                      editorLoaded={editorLoaded}
                    />
                  </div>
                  <div className="admin-input-div mt-4">
                    <label>
                      <b>Live Online Price</b>
                    </label>
                    <div className="row">
                      <div className="col-6">
                        <input
                        value={liveonlineprice}
                        onChange={(t) => {
                          setliveonlineprice(t.target.value);
                        }}
                          type="number"
                          class="form-control agendaheadall"
                          name="price"
                          placeholder="Live Online Price"
                        />
                      </div>
                      <div className="col-6">
                        <input
                        value={liveonlineofferprice}
                        onChange={(t) => {
                          setliveonlineofferprice(t.target.value);
                        }}
                          type="number"
                          class="form-control agendaheadall"
                          name="offerprice"
                          placeholder="Live Online Offer Price"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="admin-input-div mt-4">
                    <label>
                      <b>Online Self Study</b>
                    </label>
                    <Editor
                      name="courseoveriew"
                      value={onlineselfpoints}
                      onChange={(data) => {
                        setonlineselfpoints(data);
                      }}
                      editorLoaded={editorLoaded}
                    />
                  </div>
                  <div className="admin-input-div mt-4">
                    <label>
                      <b>Online Self Study</b>
                    </label>
                    <div className="row">
                      <div className="col-6">
                        <input
                        value={onlineselfprice}
                        onChange={(t) => {
                          setonlineselfprice(t.target.value);
                        }}
                          type="number"
                          class="form-control agendaheadall"
                          name="price"
                          placeholder="Online Self Study Price"
                        />
                      </div>
                      <div className="col-6">
                        <input
                        value={onlineselfofferprice}
                        onChange={(t) => {
                          setonlineselfofferprice(t.target.value);
                        }}
                          type="number"
                          class="form-control agendaheadall"
                          name="offerprice"
                          placeholder="Online Self Study Offer Price"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="admin-input-div mt-4">
                    <label>
                      <b>FAQ's</b>
                    </label>
                    <br />
                    <div class="append-btns" style={{ float: "right" }}>
                      <span
                        class="btn btn-success plusbtn"
                        onClick={() => {
                          appendcoursefaq();
                        }}
                        id="addagendapoint"
                      >
                        +
                      </span>
                      {coursefaqRef.current?.length > 1 ? (
                        <span
                          class="btn btn-danger minusbtn"
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            removecoursefaq();
                          }}
                          id="removeagendapoint"
                        >
                          -
                        </span>
                      ) : null}
                    </div>
                    {coursefaqRef.current?.map((item, index) => {
                      return (
                        <div className="mt-3" key={index}>
                          <label>{index + 1 + "."} Question</label>
                          <input
                          value={item.title}
                          onChange={(t) => {
                            changefaqtext(index, 'title', t.target.value);
                          }}
                            type=" text"
                            class="form-control agendaheadall"
                            name="agendahead[]"
                            placeholder="Question"
                          />
                          <label style={{ marginTop: 10 }}>Answer</label>
                          <Editor
                            name="courseoveriew"
                            value={item.description}
                            onChange={(data) => {
                              // setData(data);
                              changefaqtext(index, 'description', data);
                            }}
                            editorLoaded={editorLoaded}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="admin-input-div mt-4">
                    <label>
                      <b>Reviews</b>
                    </label>
                    <br />
                    <div class="append-btns" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <span
                        class="btn btn-success plusbtn"
                        onClick={() => {
                          appendreviews();
                        }}
                        id="addagendapoint"
                      >
                        +
                      </span>
                      {reviewsRef.current?.length > 1 ? (
                        <span
                          class="btn btn-danger minusbtn"
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            removereviews();
                          }}
                          id="removeagendapoint"
                        >
                          -
                        </span>
                      ) : null}
                    </div>
                    {
                      reviewsRef.current.map((item, index) => {
                        return (
                          <div key={index} style={{ borderColor: "#000", borderWidth: 1, borderStyle: 'solid', padding: 20, marginTop: 15 }}>
                      <div className="row">
                        <div className="col-md-4">
                          <input
                          value={item.name}
                          onChange={(t) => {
                            changereviewtext(index, 'name', t.target.value);
                          }}
                            type="text"
                            class="form-control agendaheadall"
                            name="agendahead[]"
                            placeholder="Name"
                          />
                        </div>
                        <div className="col-md-4">
                          <select className="form-control" 
                          value={item.rating}
                          onChange={(t) => {
                            changereviewtext(index, 'rating', t.target.value);
                          }}
                          >
                            <option selected disabled>Select Rating</option>
                            <option value={"1"}>1</option>
                            <option value={"2"}>2</option>
                            <option value={"3"}>3</option>
                            <option value={"4"}>4</option>
                            <option value={"5"}>5</option>
                          </select>
                        </div>
                        <div className="col-md-4">
                          <input
                          value={item.country}
                          onChange={(t) => {
                            changereviewtext(index, 'country', t.target.value);
                          }}
                            type="text"
                            class="form-control"
                            name=""
                            placeholder="Country"
                          />
                        </div>
                        <div className="col-md-12 mt-3">
                          <textarea type="text"
                           value={item.description}
                           onChange={(t) => {
                             changereviewtext(index, 'description', t.target.value);
                           }}
                            class="form-control" rows={4} placeholder="Review">

                          </textarea>
                        </div>
                      </div>
                    </div>
                        )
                      })
                    }
                    
                  </div>
                  <div className="admin-input-div mt-4">
                    <label>
                      <b>Trainers's</b>
                    </label>
                    <br />
                    <div class="append-btns" style={{ float: "right" }}>
                      <span
                        class="btn btn-success plusbtn"
                        onClick={() => {
                          appendcoursetrainer();
                        }}
                        id="addagendapoint"
                      >
                        +
                      </span>
                      {trainersRef.current?.length > 1 ? (
                        <span
                          class="btn btn-danger minusbtn"
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            removecoursetrainer();
                          }}
                          id="removeagendapoint"
                        >
                          -
                        </span>
                      ) : null}
                    </div>
                    {trainersRef.current?.map((item, index) => {
                      console.log('test image ',typeof item?.image);
                      // console.log('test image ',JSON.parse(item?.image));
                      return (
                        <div
                          className=""
                          style={{ marginTop: 40, marginBottom: 10 }}
                          key={index}
                        >
                          <div className="row ">
                            <div className="col-md-4">
                              <label>{index + 1 + "."} Name</label>
                              <input
                                value={item.name}
                                onChange={(t) => {
                                  changetrainertext(
                                    index,
                                    "name",
                                    t.target.value
                                  );
                                }}
                                type=" text"
                                class="form-control agendaheadall"
                                placeholder="Name of Trainer"
                              />
                            </div>
                            <div className="col-md-8">
                              <label>Degrees</label>
                              <input
                                value={item.degree}
                                onChange={(t) => {
                                  changetrainertext(
                                    index,
                                    "degree",
                                    t.target.value
                                  );
                                }}
                                type=" text"
                                class="form-control agendaheadall"
                                placeholder="Degree"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <label style={{ marginTop: 10 }}>
                                Upload Trainer Image
                              </label>
                              <input
                                type="file"
                                accept="image/*"
                                // value={item['document']}
                                onChange={(t) => {
                                  uploadtrainerimg(
                                    index,
                                    "image",
                                    t.target.files[0]
                                  );
                                }}
                                className="form-control tabinput"
                                placeholder="choose reference document"
                              />
                              {item?.image ? (
                                typeof item?.image == 'object' ? (
                                  <div className="">
                                    <Link
                                      to={item?.image?.link}
                                      target="_blank"
                                    >
                                      {item?.image?.name}
                                    </Link>
                                  </div>
                                ) : null
                              ) : null}
                            </div>
                            <div className="col-md-6"></div>
                          </div>

                          <label style={{ marginTop: 10 }}>Description</label>
                          <Editor
                            name="courseoveriew"
                            value={item?.description}
                            onChange={(data) => {
                              // setData(data);
                              changetrainertext(index, "description", data);
                            }}
                            editorLoaded={editorLoaded}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <button type="submit" onClick={() => {submitcourse()}} class="btn btn-primary mt-4">Update Course</button>
                  <br /><br />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}

export default EditCourse