import React, { useEffect, useState,useCallback,
    useRef,
    StrictMode, } from "react";
  import Sidebar from "../../components/Sidebar";
  import { useSelector, useDispatch } from "react-redux";
  import { Link, useNavigate } from "react-router-dom";
  import axios from "axios";
  import { apiUrl } from "../../config/Config";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
  import { Button, Modal } from "react-bootstrap";
  import "bootstrap/dist/css/bootstrap.min.css";
  import Overlay from "../../components/Overlay";
  import { useMemo } from 'react';
  import { AgGridReact } from "@ag-grid-community/react";
  import "@ag-grid-community/styles/ag-grid.css";
  import "@ag-grid-community/styles/ag-theme-quartz.css";
  import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
  import { ModuleRegistry } from "@ag-grid-community/core";

  ModuleRegistry.registerModules([ClientSideRowModelModule]);
  
  const Coupons = () => {
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const [showloding, setshowloding] = useState(false);
    const [showmodal, setshowmodal] = useState(false);
    const [editshowmodal, seteditshowmodal] = useState(false);
    const [cityname, setcityname] = useState("");
    const [countryname, setcountryname] = useState("");

    const [coupon, setcoupon] = useState("");
    const [amount, setamount] = useState("");
    const [selectedid, setselectedid] = useState(0);
    const [rowData, setRowData] = useState([]);
    const [colDefs, setColDefs] = useState([]);
    const paginationPageSizeSelector = useMemo(() => {
      return [10, 20, 50, 100];
    }, []);

    const options = [
        { value: 'India', label: 'India' },
        { value: 'United States of America', label: 'United States of America' },
        { value: 'United Kingdom', label: 'United Kingdom' },
        { value: 'Canada', label: 'Canada' },
        { value: 'Australia', label: 'Australia' },
      ];
  
    const defaultColDef = {
      flex: 1,
    };
  
    const clickhandle = () =>  {
      console.log('Mission Launched')
    }
    
    const fetchallcourses = async () => {
      setshowloding(true);
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .get(apiUrl + "getallcoupons", headers)
        .then((res) => {
          if (res.data.status == "success") {
            console.log('getallcoupons >>', res.data);
            var prevdata = res.data.data;
            if(prevdata?.length > 0) {
              var columnsarr = [];
              var hidecolumns = ['id', 'updated_at','created_at', 'sort'];
              var hidefilters = [];
              var columns = Object.keys(prevdata[0]);
              for(var i = 0; i < columns?.length;i++) {
                if(!hidecolumns.includes(columns[i])) {
                  var obj = {};
                  obj.field = columns[i];
                  if(!hidefilters.includes(columns[i])) {
                    obj.filter = true;
                    obj.filterParams = {
                        filterOptions: ["contains"],
                        maxNumConditions: 1,
                    };
                  }
                //   console.log('obj >>', obj)
                  columnsarr.push(obj);
                }
              }
              //edit
              var editobj = 
              {
                field: "edit",
                headerName: "Edit",
                cellRenderer: (props) => {
                  
                  return (
                    <div
                          onClick={() => {
                            editlocation(props?.data?.id, props?.data?.code, props?.data?.amount);
                            // console.log('props >>', props?.data);
                          }}
                          style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                        >
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className="sidebaricons"
                            style={{ color: "blue" }}
                          />
                        </div>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(editobj);
              //delete
              var deleteobj = 
              {
                field: "delete",
                headerName: "Delete",
                cellRenderer: (props) => {
                  
                  return (
                    <div
                          onClick={() => {
                            deletecourse(props?.data?.id, props?.data?.code);
                            // console.log('props >>', props?.data);
                          }}
                          style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="sidebaricons"
                            style={{ color: "red" }}
                          />
                        </div>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(deleteobj);
              setColDefs(columnsarr);
              setRowData(prevdata);
            }
            
            // setcoursesdata(res.data.data);
          } else {
            // alert(res.data.message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
        setshowloding(false);
    };
    const deletecourse = async (id, code) => {
      const result = window.confirm("Are you sure you want to coupon " + code );
      if (result) {
        var data = JSON.stringify({
          id: id,
        });
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .post(apiUrl + "deletecoupon", data, headers)
          .then((res) => {
            if (res.data.status == "success") {
              fetchallcourses();
            } else {
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      } else {
        // User clicked Cancel or closed the dialog
        console.log("User clicked Cancel");
      }
    };
    const submithandle = async () => {
      if (coupon && amount) {
        setshowloding(true);
        var data = {
            code: coupon,
            amount: amount
        };
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .post(apiUrl + "addnewcoupon", data, headers)
          .then((res) => {
            // console.log('res >>>', res.data)
            if (res.data.status == "success") {
              setshowmodal(false);
              setcoupon("");
              setamount("");
              fetchallcourses();
              setshowloding(false);
            } else {
              alert(res.data.message);
              setshowloding(false);
            }
          })
          .catch((err) => {
            setshowloding(false);
            alert(err.message);
          });
        setshowloding(false);
      } else {
        alert("Empty Field");
      }
    };
  
    const editlocation = async(id, code, amount) => {
      setselectedid(id);
      setcoupon(code);
      setamount(amount);
      seteditshowmodal(true);
    }
  
    const submithandle1 = async () => {
      if (coupon && amount) {
        setshowloding(true);
        var data = {
          id: selectedid,
          code: coupon,
          amount: amount
        };
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .post(apiUrl + "editcoupon", data, headers)
          .then((res) => {
            // console.log('res >>>', res.data)
            if (res.data.status == "success") {
                seteditshowmodal(false);
                setselectedid(0);
                setcoupon("");
                setamount("");
                fetchallcourses();
                setshowloding(false);
            } else {
              alert(res.data.message);
              setshowloding(false);
            }
          })
          .catch((err) => {
            setshowloding(false);
            alert(err.message);
          });
        setshowloding(false);
      } else {
        alert("Empty Field");
      }
    };

    const handlechange = (selectedOption) => {
        setcountryname(selectedOption)
    }
  
   
    useEffect(() => {
      if (userdata && Object.keys(userdata).length > 0) {
        //   fetchdata();
        fetchallcourses();
      } else {
        navigate("/newadminlogin");
      }
    }, []);
    return (
      <div className="HomeMain">
          <Overlay showloader={showloding} />
        <div className="sidebar-div">
          <Sidebar name={"Admin"} />
        </div>
        <div className="main-layout">
          <div className="mainDiv">
            <p className="oneplaceHead">COUPONS LIST</p>
            <br />
            <div className="tablebuttondiv tablebuttondivpage">
              <Link
                onClick={() => {
                  setcityname("");
                  setcountryname("")
                  setshowmodal(true);
                }}
                className="btndiv webadminmaindiv"
              >
                <p>{"Add Coupon"}</p>
              </Link>
            </div>
  
            <div
        className={
          "ag-theme-quartz"
        }
        style={{ width: "100%", height: 550 }}
      >
        <AgGridReact
        // modules={AllModules}
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          suppressRowClickSelection={true}
          groupSelectsChildren={true}
          rowSelection={"multiple"}
          pagination={true}
          paginationPageSize={10}
          paginationPageSizeSelector={paginationPageSizeSelector}
          // onGridReady={onGridReady}
        />
      </div>
  
            
  
            {/*Add modal */}
            <Modal
              show={showmodal}
              onHide={() => setshowmodal(false)}
              size="md"
              className="custom-width-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title style={{fontSize: 19}}>Add Coupon</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="webadmin-modalmainbody">
                  <div className="">
                    <label>Coupon Name</label>
                    <input
                      value={coupon}
                      onChange={(t) => {
                        setcoupon(t.target.value);
                      }}
                      type="text"
                      className="form-control tabinput"
                      placeholder="Enter Coupon name"
                    />
                  </div>
                  <div className="mt-3">
                    <label>Discount Amount</label>
                    <input
                      value={amount}
                      onChange={(t) => {
                        setamount(t.target.value);
                      }}
                      type="number"
                      className="form-control tabinput"
                      placeholder="Enter City name"
                    />
                  </div>
                  <div
                    className="webadmin-modalbtn"
                    onClick={() => {
                      submithandle();
                    }}
                  >
                    <p>Submit</p>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
  
            {/*Edit modal */}
            <Modal
              show={editshowmodal}
              onHide={() => seteditshowmodal(false)}
              size="md"
              className="custom-width-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title className="modaltitle">Edit Location</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="webadmin-modalmainbody">
                <div className="">
                    <label>Coupon Name</label>
                    <input
                      value={coupon}
                      onChange={(t) => {
                        setcoupon(t.target.value);
                      }}
                      type="text"
                      className="form-control tabinput"
                      placeholder="Enter Coupon name"
                    />
                  </div>
                  <div className="mt-3">
                    <label>Discount Amount</label>
                    <input
                      value={amount}
                      onChange={(t) => {
                        setamount(t.target.value);
                      }}
                      type="number"
                      className="form-control tabinput"
                      placeholder="Enter City name"
                    />
                  </div>
                  <div
                    className="webadmin-modalbtn"
                    onClick={() => {
                      submithandle1();
                    }}
                  >
                    <p>Update</p>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    );
  };
  
  export default Coupons;
  