import React, { useEffect } from "react";

const TawkChat = () => {
    useEffect(() => {
      // Dynamically add the Tawk.to script
      const script = document.createElement("script");
      script.src = "https://embed.tawk.to/61d46dcb3d23fa26e87100e8/default";
      script.async = true;
      script.charset = "UTF-8";
      script.setAttribute("crossorigin", "*");
      document.body.appendChild(script);
  
      // Cleanup the script when the component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }, []);
  
    return null; // This component only handles the script addition
  };
  
  export default TawkChat;