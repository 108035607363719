import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrl } from '../../config/Config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPenToSquare,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { useMemo } from 'react';
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";

const CourseList = () => {
    let navigate = useNavigate();
  const userdata = useSelector((store) => store.userReducer.userdata);
  const [coursesdata, setcoursesdata] = useState([]);
  const [rowData, setRowData] = useState([]);
    const [colDefs, setColDefs] = useState([]);
    const paginationPageSizeSelector = useMemo(() => {
      return [10, 20, 50, 100];
    }, []);
  const fetchallcourses = async() => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        'Authorization' : 'Bearer ' + userdata?.token
      },
    };
    let resp = await axios
      .get(apiUrl + "getallcourses", headers)
      .then((res) => {
        if (res.data.status == "success") {
          // console.log('getallcategories >>', res.data);
          var prevdata = res.data.data;
          // setcoursesdata(res.data.data);
          if(prevdata?.length > 0) {
            var columnsarr = [];
            var hidecolumns = ['id', 'updated_at','created_at', 'sort'];
            var hidefilters = [];
            var columns = Object.keys(prevdata[0]);
            for(var i = 0; i < columns?.length;i++) {
              if(!hidecolumns.includes(columns[i])) {
                var obj = {};
                obj.field = columns[i];
                if(!hidefilters.includes(columns[i])) {
                  obj.filter = true;
                  obj.filterParams = {
                      filterOptions: ["contains"],
                      maxNumConditions: 1,
                  };
                }
              //   console.log('obj >>', obj)
                columnsarr.push(obj);
              }
            }
            //edit
            var editobj = 
            {
              field: "edit",
              headerName: "Edit",
              cellRenderer: (props) => {
                
                return (
                  <div
                        onClick={() => {
                          // editlocation(props?.data?.id);
                          navigate('/webadmin/editcourse/'+props?.data?.id)
                          // console.log('props >>', props?.data);
                        }}
                        style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                      >
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          className="sidebaricons"
                          style={{ color: "blue" }}
                        />
                      </div>
                )
              },
              cellRendererParams: {
                onClick: clickhandle,
              },
            }
            columnsarr.push(editobj);
            //delete
            var deleteobj = 
            {
              field: "delete",
              headerName: "Delete",
              cellRenderer: (props) => {
                
                return (
                  <div
                        onClick={() => {
                          deletecourse(props?.data?.id, props?.data?.code);
                          // console.log('props >>', props?.data);
                        }}
                        style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="sidebaricons"
                          style={{ color: "red" }}
                        />
                      </div>
                )
              },
              cellRendererParams: {
                onClick: clickhandle,
              },
            }
            columnsarr.push(deleteobj);
            setColDefs(columnsarr);
            setRowData(prevdata);
          }
        } else {
          // alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }
  const clickhandle = () =>  {
    console.log('Mission Launched')
  }
  const deletecourse = async(id, title) => {
    const result = window.confirm('Are you sure you want '+title);
    if (result) {
      var data = JSON.stringify({
      id: id
    });
    const headers = {
      headers: {
        "Content-Type": "application/json",
        'Authorization' : 'Bearer ' + userdata?.token
      },
    };
    let resp = await axios
      .post(apiUrl + "deletecourse",data, headers)
      .then((res) => {
        if (res.data.status == "success") {
          fetchallcourses();
        } else {
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    } else {
      // User clicked Cancel or closed the dialog
      console.log('User clicked Cancel');
    }
    
  }
    useEffect(() => {
        if (userdata && Object.keys(userdata).length > 0) {
        //   fetchdata();
        fetchallcourses();
        } else {
          navigate("/newadminlogin");
        }
      }, []);
      return (
        <div className="HomeMain">
          <div className="sidebar-div">
            <Sidebar name={"Admin"} />
          </div>
          <div className="main-layout">
            <div className="mainDiv">
              <p className="oneplaceHead">COURSES LIST</p>
              <br /><br />
              {/* <table class="table table-bordered">
                <thead>
                  <tr>
                    <th style={{minWidth: 100}}>Sr No.</th>
                    <th>Course Name</th>
                    <th>Course Slug</th>
                    <th>Course Category</th>
                    <th>Status</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    coursesdata?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.title}</td>
                          <td>{item.slug}</td>
                          <td>{item.category}</td>
                          <td><input type="checkbox" class="myCheckbox" data-id={item.id} style={{margin: 15}}   /></td>
                          <td>
                            <Link to={'/webadmin/editcourse/'+item.id}>
                              <FontAwesomeIcon icon={faPenToSquare} className="sidebaricons" />
                            </Link>
                          </td>
                          <td>
                            <div onClick={() => {deletecourse(item.id, item.title)}}>
                              <FontAwesomeIcon icon={faTrash} className="sidebaricons" style={{color: 'red'}} />
                            </div>
                            
                            </td>
                        </tr>
                      );
                    })
                  }

                </tbody>
              </table> */}
              <div
              className={"ag-theme-quartz"}
              style={{ width: "100%", height: 550 }}
            >
              <AgGridReact
                // modules={AllModules}
                rowData={rowData}
                columnDefs={colDefs}
                // defaultColDef={defaultColDef}
                suppressRowClickSelection={true}
                groupSelectsChildren={true}
                rowSelection={"multiple"}
                pagination={true}
                paginationPageSize={10}
                paginationPageSizeSelector={paginationPageSizeSelector}
                // onGridReady={onGridReady}
              />
            </div>
            </div>
          </div>
        </div>
      );
}

export default CourseList